var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormikComponents } from '@iown/shared/components';
import { AuthScope, useLoginMutation } from 'src/types/graphql/types';
import { useContext } from 'react';
import { StoreContext } from 'src/store';
import { NotificationContext, NotificationVariant, } from 'src/App/NotificationCentre';
import { Link } from 'react-router-dom';
export var SignIn = function () {
    var store = useContext(StoreContext);
    var _a = __read(useLoginMutation({
        fetchPolicy: 'no-cache',
    }), 2), login = _a[0], _b = _a[1], loading = _b.loading, error = _b.error;
    var notify = useContext(NotificationContext).notify;
    if (error) {
        notify({
            variant: NotificationVariant.ERROR,
            title: 'Something went wrong',
            message: error.message,
            timeout: 5000,
        });
    }
    return (_jsxs("div", __assign({ className: "flex min-h-screen bg-iown-white" }, { children: [_jsx("div", __assign({ className: "flex flex-col justify-center flex-1 w-2/5 px-4 py-12 border-r lg-w1/4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 border-r-iown-light" }, { children: _jsxs("div", __assign({ className: "w-full max-w-xs mx-auto" }, { children: [_jsxs("div", { children: [_jsxs("h1", __assign({ className: "text-xl font-medium text-gray-900" }, { children: ["IOWN[ ", _jsx("span", { children: "portal" }), " ]"] })), _jsx("h2", __assign({ className: "text-lg text-gray-600" }, { children: "Logga in till ditt konto" }))] }), _jsx("div", __assign({ className: "mt-8" }, { children: _jsx("div", __assign({ className: "mt-6" }, { children: _jsx(Formik, __assign({ initialValues: { email: '', password: '' }, validationSchema: LoginSchema, onSubmit: function (values, _a) {
                                        var setFieldError = _a.setFieldError, setSubmitting = _a.setSubmitting;
                                        return __awaiter(void 0, void 0, void 0, function () {
                                            var data, error_1;
                                            var _b, _c;
                                            return __generator(this, function (_d) {
                                                switch (_d.label) {
                                                    case 0:
                                                        if (!values.email) {
                                                            setFieldError('email', 'Please provide a valid email address');
                                                        }
                                                        if (!values.password) {
                                                            setFieldError('password', 'Please provide password');
                                                        }
                                                        _d.label = 1;
                                                    case 1:
                                                        _d.trys.push([1, 3, , 4]);
                                                        return [4 /*yield*/, login({
                                                                variables: __assign(__assign({}, values), { scope: AuthScope.Client }),
                                                            })];
                                                    case 2:
                                                        data = (_d.sent()).data;
                                                        store.setToken((_b = data === null || data === void 0 ? void 0 : data.login) === null || _b === void 0 ? void 0 : _b.token);
                                                        store.setRefreshToken((_c = data === null || data === void 0 ? void 0 : data.login) === null || _c === void 0 ? void 0 : _c.refreshToken); // ??!!?
                                                        return [3 /*break*/, 4];
                                                    case 3:
                                                        error_1 = _d.sent();
                                                        console.error(error_1);
                                                        setSubmitting(false);
                                                        return [3 /*break*/, 4];
                                                    case 4: return [2 /*return*/];
                                                }
                                            });
                                        });
                                    } }, { children: function (_a) {
                                        var isSubmitting = _a.isSubmitting, dirty = _a.dirty, isValid = _a.isValid;
                                        return (_jsxs(Form, __assign({ className: "space-y-6" }, { children: [_jsx(Field, __assign({ name: "email" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "Email", trailingIcon: faEnvelope, placeholder: "namn@exempel.se" }, props))); } })), _jsx(Field, __assign({ name: "password" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ inputType: "password", label: "L\u00F6senord", trailingIcon: faLock, placeholder: "l\u00F6senord" }, props))); } })), _jsx(Button, { loading: loading || isSubmitting, disabled: isSubmitting || !dirty || !isValid, className: "w-full", 
                                                    // variant="primary"
                                                    label: "Logga in", type: "submit" }), _jsx("p", { children: _jsx(Link, __assign({ className: "w-full text-sm text-indigo-400 hover:text-indigo-500", to: "/forgot-password" }, { children: "Gl\u00F6mt ditt l\u00F6senord?" })) })] })));
                                    } })) })) }))] })) })), _jsx("div", __assign({ className: "relative flex-1 hidden w-0 lg:block" }, { children: _jsx("img", { className: "absolute inset-0 object-cover w-full h-full grayscale-75", src: "https://images.unsplash.com/".concat(UNSPLASH_IMGS[Math.floor(Math.random() * UNSPLASH_IMGS.length)], "=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"), alt: "" }) }))] })));
};
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation login($email: String!, $password: String!, $scope: AuthScope!) {\n    login(email: $email, password: $password, scope: $scope) {\n      user {\n        userName\n        email\n        role\n      }\n      token\n      refreshToken\n    }\n  }\n"], ["\n  mutation login($email: String!, $password: String!, $scope: AuthScope!) {\n    login(email: $email, password: $password, scope: $scope) {\n      user {\n        userName\n        email\n        role\n      }\n      token\n      refreshToken\n    }\n  }\n"])));
var UNSPLASH_IMGS = [
    // 'photo-1615209853186-e4bd66602508?iixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
    'photo-1466611653911-95081537e5b7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
    // 'photo-1598298809876-32b6a79f716a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
];
var LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Not a valid email address')
        .required('Email Required'),
    password: Yup.string().required('Password Required!'),
});
var templateObject_1;
