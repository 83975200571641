import { makeAutoObservable } from 'mobx';
import React from 'react';
var Store = /** @class */ (function () {
    function Store(token, refreshToken, darkMode) {
        var _this = this;
        this.setDarkMode = function (value) {
            localStorage.setItem('dark-mode', value);
            _this.darkMode = value;
        };
        this.setToken = function (token) {
            if (token) {
                localStorage.setItem('auth_token', token);
            }
            else {
                localStorage.removeItem('auth_token');
            }
            _this.token = token;
        };
        this.setRefreshToken = function (token) {
            if (token) {
                localStorage.setItem('refresh_token', token);
            }
            else {
                localStorage.removeItem('refresh_token');
            }
            _this.refreshToken = token;
        };
        this.clearTokens = function () {
            console.log('clearing tokens');
            localStorage.removeItem('auth_token');
            localStorage.removeItem('refresh_token');
            _this.token = undefined;
            _this.refreshToken = undefined;
        };
        this.setUserId = function (id) { return (_this.userId = id); };
        this.token = token;
        this.refreshToken = refreshToken;
        this.darkMode = darkMode !== null && darkMode !== void 0 ? darkMode : 'off';
        makeAutoObservable(this);
    }
    Object.defineProperty(Store.prototype, "isAuthenticated", {
        get: function () {
            return !!this.token;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Store.prototype, "isDarkModeEnabled", {
        get: function () {
            return this.darkMode === 'on';
        },
        enumerable: false,
        configurable: true
    });
    return Store;
}());
export var store = (function () {
    var _a, _b, _c;
    var token = (_a = localStorage.getItem('auth_token')) !== null && _a !== void 0 ? _a : undefined;
    var refreshToken = (_b = localStorage.getItem('refresh_token')) !== null && _b !== void 0 ? _b : undefined;
    var darkMode = (_c = localStorage.getItem('dark-mode')) !== null && _c !== void 0 ? _c : 'off';
    return new Store(token, refreshToken, darkMode);
})();
export var StoreContext = React.createContext(store);
