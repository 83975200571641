var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { LoadingBar, Panel } from 'src/components';
import { useCurrentUserContractsQuery } from 'src/types/graphql/types';
import { PreviewContract } from './components';
import { SignableContracts } from './components/SignableContracts';
export var Contracts = function () {
    var _a, _b, _c;
    var _d = useCurrentUserContractsQuery(), data = _d.data, loading = _d.loading, error = _d.error, refetch = _d.refetch;
    if (!data && loading) {
        return (_jsx(Panel, { children: _jsx("div", __assign({ className: "" }, { children: _jsx(LoadingBar, { label: "Laddar" }) })) }));
    }
    return (_jsx("div", __assign({ className: "grid grid-cols-6 gap-6" }, { children: _jsxs("div", __assign({ className: "col-span-6 space-y-4 lg:col-span-5" }, { children: [_jsx(Panel, { children: _jsxs("div", __assign({ className: "flex items-center space-x-4" }, { children: [_jsx("div", __assign({ className: "flex items-center justify-center p-4 text-lg rounded text-sky-700 bg-sky-50" }, { children: _jsx(FontAwesomeIcon, { icon: faFileSignature }) })), _jsx("div", { children: _jsx("h1", __assign({ className: "text-xl font-semibold sm:text-2xl" }, { children: "Avtal" })) })] })) }), !data && loading ? (_jsx(Panel, { children: _jsx(LoadingBar, { label: "Laddar" }) })) : (_jsxs(_Fragment, { children: [((_a = data === null || data === void 0 ? void 0 : data.currentUser.contracts) !== null && _a !== void 0 ? _a : []).length === 0 && (_jsx(PreviewContract, {})), data && (data === null || data === void 0 ? void 0 : data.currentUser.id) && (_jsx(SignableContracts, { userId: data === null || data === void 0 ? void 0 : data.currentUser.id, 
                            // contracts={[] as Contract[]}
                            contracts: (((_c = (_b = data === null || data === void 0 ? void 0 : data.currentUser) === null || _b === void 0 ? void 0 : _b.contracts) !== null && _c !== void 0 ? _c : []).length > 0
                                ? data.currentUser.contracts
                                : []), refetch: refetch }))] }))] })) })));
};
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query currentUserContracts {\n    currentUser {\n      id\n      firstName\n      lastName\n      contracts {\n        id\n        createdAt\n        dueDate\n        status\n        filename\n        signedAndSealed\n        userContractURLs {\n          id\n          userId\n          email\n          url\n        }\n        signedByUsers {\n          id\n          userId\n          email\n          signedDate\n        }\n      }\n    }\n  }\n"], ["\n  query currentUserContracts {\n    currentUser {\n      id\n      firstName\n      lastName\n      contracts {\n        id\n        createdAt\n        dueDate\n        status\n        filename\n        signedAndSealed\n        userContractURLs {\n          id\n          userId\n          email\n          url\n        }\n        signedByUsers {\n          id\n          userId\n          email\n          signedDate\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
