var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faBars, faTimes, faBell, faSignOut, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext } from 'react';
import { Link, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { useBoolean } from 'react-hanger';
import { useClickOutside } from '@iown/shared/hooks';
import { UserProfile, UserProfileMobile } from './UserProfile';
import { useLogoutMutation } from 'src/types/graphql/types';
import { StoreContext } from 'src/store';
var iownLogoClasses = 'bg-clip-text bg-gradient-to-r from-cyan-500 via-teal-300 to-green-200 text-transparent opacity-75 hover:opacity-50';
export var NavigationLayout = function (_a) {
    var children = _a.children;
    return (_jsxs("div", __assign({ className: "relative min-h-screen antialiased bg-gray-100" }, { children: [_jsx(MobileNav, {}), _jsx("div", { className: "w-full h-20 -mb-16 md:hidden bg-gradient-to-r to-teal-600 from-cyan-800" }), _jsxs("div", __assign({ className: "relative flex flex-col" }, { children: [_jsx("header", __assign({ className: "hidden pb-24 md:block bg-gradient-to-r to-teal-600 from-cyan-800" }, { children: _jsx(DesktopNav, {}) })), _jsx("main", __assign({ className: "relative w-full max-w-3xl px-4 pb-8 mx-auto md:-mt-24 sm:px-6 lg:px-8 lg:max-w-7xl" }, { children: children }))] }))] })));
};
var DesktopNav = function () {
    var navigate = useNavigate();
    return (_jsx("div", __assign({ className: "max-w-3xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8" }, { children: _jsxs("div", __assign({ className: "relative flex flex-wrap items-center justify-between" }, { children: [_jsx("div", __assign({ className: "static flex-shrink-0 py-5 cursor-pointer", onClick: function () { return navigate('/'); } }, { children: _jsxs("p", __assign({ className: classNames('w-auto h-8 text-lg font-semibold tracking-wide select-none', iownLogoClasses) }, { children: ["IOWN[ ", _jsx("span", __assign({ className: "" }, { children: "Portal" })), " ]"] })) })), _jsxs("div", __assign({ className: "ml-4 flex items-center lg:py-5 lg:pr-0.5" }, { children: [_jsx("button", __assign({ className: "flex-shrink-0 p-1 mr-4 text-teal-100 rounded-full hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white" }, { children: _jsx("div", __assign({ className: "flex items-center justify-center w-8 h-8" }, { children: _jsx(FontAwesomeIcon, { icon: faBell }) })) })), _jsx(UserProfile, {})] })), _jsx("div", __assign({ className: "w-full py-5 lg:border-t lg:border-white lg:border-opacity-20" }, { children: _jsxs("div", __assign({ className: "flex items-center space-x-4" }, { children: [_jsx(NavLink, { to: "/", label: "Startsida", onClick: function () { return window.scrollTo(0, 0); } }), _jsx(NavLink, { to: "/contracts", label: "Avtal", onClick: function () { return window.scrollTo(0, 0); } })] })) }))] })) })));
};
// const DesktopNav: React.FC<{ slim?: boolean }> = ({ slim }) => {
//   return (
//     <Nav className="flex items-center justify-between w-full max-w-3xl p-2 px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8" />
//   )
// }
var MobileNav = function () {
    var isOpen = useBoolean(false);
    var _a = __read(useClickOutside(isOpen.setFalse), 2), menuRef = _a[0], toggleRef = _a[1];
    var navigate = useNavigate();
    return (_jsxs("nav", __assign({ className: "flex items-center md:pt-12 md:pb-0 md:hidden" }, { children: [_jsx("div", __assign({ className: "block w-full py-2 text-teal-300 bg-gradient-to-r to-teal-600 from-cyan-800 md:hidden" }, { children: _jsxs("div", __assign({ className: "flex items-center px-3 pl-5 text-lg" }, { children: [_jsxs("div", __assign({ className: classNames('font-medium', iownLogoClasses), onClick: function () { return navigate('/'); } }, { children: ["IOWN[ ", _jsx("span", __assign({ className: "" }, { children: "Portal" })), " ]"] })), _jsx("div", __assign({ ref: toggleRef, onClick: isOpen.toggle, "aria-label": "Open Menu", className: "p-4 ml-auto " }, { children: _jsx(FontAwesomeIcon, { className: "text-lg", icon: faBars }) }))] })) })), _jsx(AnimatePresence, { children: isOpen.value && (_jsx(motion.div, __assign({ className: "z-50 md:hidden", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } }, { children: _jsx("div", __assign({ className: "fixed inset-0 flex p-2 bg-gray-700 bg-opacity-40" }, { children: _jsxs(motion.div, __assign({ className: "relative flex flex-col h-[520px] w-full bg-white rounded shadow", ref: menuRef, initial: "closed", animate: "open", exit: "closed", transition: { duration: 0.2 }, variants: menuVariants }, { children: [_jsx("div", __assign({ className: "absolute top-0 right-0 p-1 -mr-14" }, { children: _jsx("button", __assign({ className: "flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600", "aria-label": "Close sidebar", onClick: isOpen.setFalse }, { children: _jsx(FontAwesomeIcon, { className: "text-gray-800", icon: faTimes, size: "lg" }) })) })), _jsx(Nav, { close: function () {
                                        isOpen.setFalse();
                                        window.scrollTo(0, 0);
                                    } })] })) })) }))) })] })));
};
var Nav = function (_a) {
    var close = _a.close;
    var store = useContext(StoreContext);
    var _b = __read(useLogoutMutation({
        fetchPolicy: 'no-cache',
    }), 1), logout = _b[0];
    return (_jsxs("nav", __assign({ className: "space-y-6 " }, { children: [_jsxs("div", __assign({ className: "pt-3 space-y-2 " }, { children: [_jsxs("div", __assign({ className: "flex items-center px-4 " }, { children: [_jsxs("div", __assign({ className: "font-medium text-teal-700" }, { children: ["IOWN[ ", _jsx("span", __assign({ className: "" }, { children: "Portal" })), " ]"] })), _jsx("div", __assign({ "aria-label": "Close Menu", className: "p-2 ml-auto text-teal-400", onClick: close }, { children: _jsx(FontAwesomeIcon, { className: "text-lg", icon: faTimes }) }))] })), _jsxs("div", __assign({ className: "px-2 space-y-1" }, { children: [_jsx(NavLink, { to: "/", label: "Startsida", onClick: close }), _jsx(NavLink, { to: "/contracts", label: "Avtal", onClick: close })] }))] })), _jsx("div", { className: "border-t border-gray-300" }), _jsxs("div", __assign({ className: "px-4 space-y-2" }, { children: [_jsx(UserProfileMobile, { closeMenu: close }), _jsx("div", __assign({ className: "pt-0" }, { children: _jsxs("button", __assign({ className: "'md:text-white text-gray-700 md:text-sm select-none font-semibold cursor-pointer justify-center rounded-md bg-teal-600 bg-opacity-0 md:bg-white md:bg-opacity-0 px-3 py-2 hover:bg-opacity-10',", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0: return [4 /*yield*/, logout({
                                                variables: { refreshToken: (_a = store === null || store === void 0 ? void 0 : store.refreshToken) !== null && _a !== void 0 ? _a : '' },
                                            })];
                                        case 1:
                                            _b.sent();
                                            store.setToken('');
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }, { children: [_jsx(FontAwesomeIcon, { className: "pr-2", icon: faSignOut }), " Logga ut"] })) }))] }))] })));
};
var NavLink = function (_a) {
    var label = _a.label, icon = _a.icon, to = _a.to, isLeftSidebarToggled = _a.isLeftSidebarToggled, onClick = _a.onClick, props = __rest(_a, ["label", "icon", "to", "isLeftSidebarToggled", "onClick"]);
    var resolved = useResolvedPath(to);
    var match = useMatch({
        path: resolved.pathname,
        end: to === '/' ? true : false,
    });
    var navigate = useNavigate();
    var active = match;
    return (_jsx("div", __assign({}, props, { className: classNames('md:text-white text-gray-700 md:text-sm select-none font-semibold cursor-pointer justify-center rounded-md bg-teal-600 bg-opacity-0 md:bg-white md:bg-opacity-0 px-3 py-2 hover:bg-opacity-10', {
            'md:bg-opacity-10 bg-opacity-10': active,
        }), onClick: function () {
            navigate(to);
            onClick && onClick();
        } }, { children: _jsxs(Link, __assign({ to: to, className: "flex items-center space-x-3" }, { children: [icon && (_jsx(FontAwesomeIcon, { icon: icon, className: classNames('transition duration-150 ease-in-out', isLeftSidebarToggled ? 'mr-2 w-5 h-5' : 'w-6 h-6'), fixedWidth: true })), _jsx("p", { children: label })] })) })));
};
var menuVariants = {
    open: {
        scale: 1,
        y: 0,
        opacity: 1,
    },
    closed: { scale: 0.4, y: -140, opacity: 0 },
};
