var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthenticatedRoutes, UnauthenticatedRoutes } from './Routes';
import { StoreContext } from 'src/store';
import { observer } from 'mobx-react';
import { Notifications } from './NotificationCentre';
import { Provider } from 'mobx-react';
import { store } from 'src/store';
var App = function () {
    return (_jsxs(Provider, __assign({ store: __assign({}, store) }, { children: [_jsx(Notifications, {}), _jsx(AppRoutes, {})] })));
};
var AppRoutes = observer(function () {
    var isAuthenticated = useContext(StoreContext).isAuthenticated;
    return (_jsx(Router, { children: isAuthenticated ? _jsx(AuthenticatedRoutes, {}) : _jsx(UnauthenticatedRoutes, {}) }));
});
export default App;
