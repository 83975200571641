var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate, useParams } from 'react-router';
import { gql } from '@apollo/client';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormikComponents } from '@iown/shared/components';
import { useContext } from 'react';
import { NotificationContext, NotificationVariant, } from 'src/App/NotificationCentre';
import classNames from 'classnames';
import { useResetPasswordMutation } from 'src/types/graphql/types';
export var ResetPassword = function () {
    var token = useParams().token;
    var notify = useContext(NotificationContext).notify;
    var _a = __read(useResetPasswordMutation({
        onError: function (error) {
            return notify({
                variant: NotificationVariant.ERROR,
                title: 'Något gick fel.',
                message: 'Länken verkar inte längre vara giltig. Vänligen försök igen eller kontakta systemadministratör.',
                timeout: 12000,
            });
        },
    }), 2), resetPassword = _a[0], _b = _a[1], data = _b.data, loading = _b.loading;
    var navigate = useNavigate();
    return (_jsxs("div", __assign({ className: "relative flex min-h-screen antialiased bg-iown-white" }, { children: [_jsx("div", __assign({ className: "flex flex-col justify-center flex-1 w-2/5 px-4 py-12 border-r lg-w1/4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 border-r-iown-light" }, { children: _jsxs("div", __assign({ className: "w-full max-w-xs mx-auto" }, { children: [_jsxs("div", { children: [_jsxs("h1", __assign({ className: "text-xl font-medium text-gray-900" }, { children: ["IOWN[ ", _jsx("span", { children: "Portal" }), " ]"] })), _jsxs("div", __assign({ className: "pt-6 space-y-2" }, { children: [_jsx("h2", __assign({ className: "font-semibold text-gray-700" }, { children: "\u00C5terst\u00E4ll l\u00F6senord" })), _jsx("p", __assign({ className: "text-sm text-gray-700" }, { children: "V\u00E4nligen ange ett nytt l\u00F6senord." }))] }))] }), _jsx("div", __assign({ className: classNames('mt-8') }, { children: _jsx("div", __assign({ className: "mt-6" }, { children: _jsx(Formik, __assign({ initialValues: { password: '', passwordConfirm: '' }, validationSchema: PasswordSchema, onSubmit: function (values, _a) {
                                        var setFieldError = _a.setFieldError, setSubmitting = _a.setSubmitting;
                                        return __awaiter(void 0, void 0, void 0, function () {
                                            var data_1, error_1;
                                            return __generator(this, function (_b) {
                                                switch (_b.label) {
                                                    case 0:
                                                        _b.trys.push([0, 2, , 3]);
                                                        return [4 /*yield*/, resetPassword({
                                                                variables: {
                                                                    token: token !== null && token !== void 0 ? token : '',
                                                                    password: values.password,
                                                                },
                                                            })];
                                                    case 1:
                                                        data_1 = (_b.sent()).data;
                                                        setSubmitting(false);
                                                        if (data_1 === null || data_1 === void 0 ? void 0 : data_1.resetPassword) {
                                                            notify({
                                                                variant: NotificationVariant.OK,
                                                                title: 'Lösenordet har ändrats',
                                                                message: 'Vänligen logga in med ditt nya lösenord.',
                                                            });
                                                            navigate('/');
                                                        }
                                                        return [3 /*break*/, 3];
                                                    case 2:
                                                        error_1 = _b.sent();
                                                        setSubmitting(false);
                                                        return [3 /*break*/, 3];
                                                    case 3: return [2 /*return*/];
                                                }
                                            });
                                        });
                                    } }, { children: function (_a) {
                                        var isSubmitting = _a.isSubmitting, dirty = _a.dirty, isValid = _a.isValid;
                                        return (_jsxs(Form, __assign({ className: "space-y-6" }, { children: [_jsx(Field, __assign({ name: "password" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ inputType: "password", label: "L\u00F6senord", trailingIcon: faLock, placeholder: "l\u00F6senord" }, props))); } })), _jsx(Field, __assign({ name: "passwordConfirm" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ inputType: "password", label: "Bekr\u00E4fta l\u00F6senord", trailingIcon: faLock, placeholder: "l\u00F6senord" }, props))); } })), _jsx(Button, { loading: loading, disabled: isSubmitting || !dirty || !isValid || loading, className: "w-full", 
                                                    // variant="primary"
                                                    label: "\u00C5terst\u00E4ll l\u00F6senord", type: "submit" })] })));
                                    } })) })) }))] })) })), _jsx("div", __assign({ className: "relative flex-1 hidden w-0 lg:block" }, { children: _jsx("img", { className: "absolute inset-0 object-cover w-full h-full grayscale-75", src: "https://images.unsplash.com/".concat(UNSPLASH_IMGS[Math.floor(Math.random() * UNSPLASH_IMGS.length)], "=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"), alt: "" }) }))] })));
};
var UNSPLASH_IMGS = [
    // 'photo-1615209853186-e4bd66602508?iixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
    'photo-1594818379496-da1e345b0ded?iixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
    // 'photo-1598298809876-32b6a79f716a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
];
var PasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Lösenorder är för kort - 8 karaktärer minimum.')
        .required('Lösenord är obligatoriskt'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Lösenorden matchar inte'),
});
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation resetPassword($token: String!, $password: String!) {\n    resetPassword(token: $token, password: $password)\n  }\n"], ["\n  mutation resetPassword($token: String!, $password: String!) {\n    resetPassword(token: $token, password: $password)\n  }\n"])));
var templateObject_1;
