var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { getIn, useFormikContext } from 'formik';
export var Checkbox = function (_a) {
    var _b;
    var labelText = _a.label, helpText = _a.helpText, field = _a.field, // { name, value, onChange, onBlur }
    form = _a.form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    props = __rest(_a, ["label", "helpText", "field", "form"]);
    var error = getIn(form.errors, field.name);
    var touched = getIn(form.touched, field.name);
    var hasError = error && touched;
    var disabled = (_b = props.disabled) !== null && _b !== void 0 ? _b : false;
    var ctx = useFormikContext();
    var _c = __read(useState(nanoid()), 1), id = _c[0];
    return (_jsxs("div", __assign({ className: "relative flex items-start" }, { children: [_jsx("div", __assign({ className: "absolute flex items-center h-5" }, { children: _jsx("input", __assign({ id: id, type: "checkbox", className: classNames('w-4 h-4 text-indigo-600 transition duration-150 ease-in-out form-checkbox', disabled && 'bg-gray-100', hasError && 'border-red-300') }, field, { checked: field.value, disabled: disabled || (ctx === null || ctx === void 0 ? void 0 : ctx.isSubmitting) })) })), _jsxs("div", __assign({ className: "space-y-1 leading-5 pl-7" }, { children: [_jsx("label", __assign({ htmlFor: id, className: "text-sm font-medium text-gray-700" }, { children: labelText })), helpText && _jsx("p", __assign({ className: "text-sm text-gray-500" }, { children: helpText })), hasError && (_jsx("p", __assign({ className: "text-sm text-red-600 ", id: "".concat(id, "-error") }, { children: error })))] }))] })));
};
