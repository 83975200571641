var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useClickOutside } from '@iown/shared/hooks';
import { StoreContext } from 'src/store';
import { useCurrentUserQuery, useLogoutMutation } from 'src/types/graphql/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
export var UserProfile = function () {
    var store = useContext(StoreContext);
    var _a = __read(useState(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    var _b = __read(useClickOutside(function () { return setIsOpen(false); }), 2), menuRef = _b[0], togglerRef = _b[1];
    var navigate = useNavigate();
    var data = useCurrentUserQuery({
        onCompleted: function (data) {
            store.setUserId(data.currentUser.id);
        },
    }).data;
    var _c = __read(useLogoutMutation({
        fetchPolicy: 'no-cache',
    }), 1), logout = _c[0];
    return (_jsxs("div", __assign({ className: "relative text-left" }, { children: [_jsx("button", __assign({ ref: togglerRef, type: "button", className: "flex text-sm bg-white rounded-full ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100", id: "options-menu", "aria-haspopup": "true", "aria-expanded": "true", onClick: function () { return setIsOpen(function (prev) { return !prev; }); } }, { children: _jsx("div", __assign({ className: "flex items-center justify-center w-8 h-8 overflow-hidden text-gray-600 rounded-full" }, { children: (data === null || data === void 0 ? void 0 : data.currentUser.profilePicture) ? (_jsx("img", { className: "object-cover w-full h-full ", src: data.currentUser.profilePicture, alt: "profilbild" })) : (_jsx(FontAwesomeIcon, { icon: faUser })) })) })), isOpen && (_jsx("div", __assign({ ref: menuRef, className: "absolute z-10 mx-3 mt-1 origin-top w-[200px] bg-white divide-y dark:divide-slate-700 divide-gray-200 rounded-md shadow-xl -left-44 dark:bg-slate-800 ring-1 ring-black ring-opacity-5", role: "menu", "aria-orientation": "vertical", "aria-labelledby": "options-menu" }, { children: _jsxs("div", __assign({ className: "py-2" }, { children: [_jsx("button", __assign({ className: "block w-full px-4 py-2 space-x-2 text-left text-gray-600 cursor-pointer hover:bg-gray-100 dark:text-slate-400 dark:hover:bg-slate-700 hover:text-gray-700", role: "menuitem", onClick: function () {
                                navigate('/profile');
                                setIsOpen(false);
                            } }, { children: _jsx("span", { children: "Din profil" }) })), _jsx("div", __assign({ className: "block px-4 py-2 space-x-2 text-gray-400 cursor-not-allowed dark:text-slate-400 dark:hover:bg-slate-700 hover:bg-gray-100", role: "menuitem" }, { children: _jsx("span", { children: "Inst\u00E4llningar" }) })), _jsx("button", __assign({ onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0: return [4 /*yield*/, logout({
                                                variables: { refreshToken: (_a = store === null || store === void 0 ? void 0 : store.refreshToken) !== null && _a !== void 0 ? _a : '' },
                                            })];
                                        case 1:
                                            _b.sent();
                                            store.setToken('');
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, className: "block w-full px-4 py-2 space-x-2 text-left text-gray-600 cursor-pointer hover:bg-gray-100 dark:text-slate-400 dark:hover:bg-slate-700 hover:text-gray-700", role: "menuitem" }, { children: _jsx("span", { children: "Logga ut" }) }))] })) })))] })));
};
export var UserProfileMobile = function (_a) {
    var closeMenu = _a.closeMenu;
    var store = useContext(StoreContext);
    var data = useCurrentUserQuery({
        onCompleted: function (data) {
            store.setUserId(data.currentUser.id);
        },
    }).data;
    var navigate = useNavigate();
    return (_jsxs("div", __assign({ onClick: function () {
            navigate('/profile');
            closeMenu();
        } }, { children: [_jsxs("div", __assign({ className: "flex items-center pl-3 space-x-3" }, { children: [_jsx("div", __assign({ className: "flex items-center justify-center w-10 h-10 overflow-hidden text-gray-600 rounded-full" }, { children: (data === null || data === void 0 ? void 0 : data.currentUser.profilePicture) ? (_jsx("img", { className: "object-cover w-full h-full ", src: data.currentUser.profilePicture, alt: "profilbild" })) : (_jsx(FontAwesomeIcon, { icon: faUser })) })), _jsxs("div", __assign({ className: "flex-col space-y-0" }, { children: [_jsx("p", __assign({ className: "font-semibold leading-tight text-gray-800" }, { children: "".concat(data === null || data === void 0 ? void 0 : data.currentUser.firstName, " ").concat(data === null || data === void 0 ? void 0 : data.currentUser.lastName) })), _jsx("p", __assign({ className: "text-sm font-semibold leading-tight text-gray-500 truncate" }, { children: data === null || data === void 0 ? void 0 : data.currentUser.email }))] }))] })), _jsx("button", __assign({ className: "justify-center w-full px-3 py-2 pt-6 font-semibold text-left text-gray-700 bg-teal-600 bg-opacity-0 rounded-md cursor-pointer select-none md:text-white md:text-sm md:bg-white md:bg-opacity-0 hover:bg-opacity-10" }, { children: "Din Profil" }))] })));
};
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query currentUser {\n    currentUser {\n      id\n      userName\n      firstName\n      lastName\n      email\n      role\n      profilePicture\n    }\n  }\n"], ["\n  query currentUser {\n    currentUser {\n      id\n      userName\n      firstName\n      lastName\n      email\n      role\n      profilePicture\n    }\n  }\n"])));
gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation logout($refreshToken: String!) {\n    logout(refreshToken: $refreshToken)\n  }\n"], ["\n  mutation logout($refreshToken: String!) {\n    logout(refreshToken: $refreshToken)\n  }\n"])));
var templateObject_1, templateObject_2;
