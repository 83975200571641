var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { getIn, useFormikContext } from 'formik';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export var Input = function (_a) {
    var _b, _c;
    var id = _a.id, inputType = _a.inputType, labelText = _a.label, helpText = _a.helpText, hint = _a.hint, trailingIcon = _a.trailingIcon, prependIcon = _a.prependIcon, field = _a.field, // { name, value, onChange, onBlur }
    form = _a.form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    onChange = _a.onChange, value = _a.value, autoFocus = _a.autoFocus, inputClassName = _a.inputClassName, wrapperClassName = _a.wrapperClassName, spellCheck = _a.spellCheck, props = __rest(_a, ["id", "inputType", "label", "helpText", "hint", "trailingIcon", "prependIcon", "field", "form", "onChange", "value", "autoFocus", "inputClassName", "wrapperClassName", "spellCheck"]);
    var error = ((_b = getIn(form.errors, field.name)) !== null && _b !== void 0 ? _b : '');
    var touched = getIn(form.touched, field.name);
    var hasError = error && touched;
    var disabled = (_c = props.disabled) !== null && _c !== void 0 ? _c : false;
    var ctx = useFormikContext();
    var _d = __read(useState(nanoid()), 1), internalId = _d[0];
    return (_jsxs("div", __assign({ className: classNames('relative space-y-1', wrapperClassName) }, { children: [_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("label", __assign({ htmlFor: id !== null && id !== void 0 ? id : internalId, className: "block text-sm font-medium text-gray-700 dark:text-slate-600" }, { children: labelText })), !!hint && (_jsx("span", __assign({ className: "text-sm leading-5 text-gray-500" }, { children: hint })))] })), _jsxs("div", __assign({ className: "relative" }, { children: [prependIcon && (_jsx("div", __assign({ className: "absolute inset-y-0 left-0 flex items-center pl-3 text-red-600 pointer-events-none" }, { children: _jsx(FontAwesomeIcon, { className: "w-4 h-4 text-gray-400", icon: prependIcon, fixedWidth: true }) }))), _jsx("input", __assign({ id: id !== null && id !== void 0 ? id : internalId, className: classNames('block w-full dark:bg-slate-800 font-semibold placeholder-gray-500/80 bg-gray-200/60 border-transparent dark:text-slate-400 dark:border dark:border-slate-800 dark:focus:ring-2 dark:focus:ring-slate-700 dark:border-slate-800 px-3 py-2 dark:placeholder-slate-700 placeholder-gray-400 border rounded-lg shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm', hasError &&
                            'border-red-300 text-red-900 pr-10 dark:placeholder-red-600 placeholder-red-300 dark:focus:border-red-600 focus:border-red-300 focus:shadow-outline-red', disabled &&
                            'bg-gray-100 cursor-not-allowed dark:bg-slate-800 dark:text-slate-600 text-gray-600', prependIcon && 'pl-9', inputType === 'number' && 'text-center', inputType === 'number' &&
                            field.value === 0 &&
                            'text-transparent dark:text-transparent', inputClassName), spellCheck: spellCheck !== null && spellCheck !== void 0 ? spellCheck : false, autoFocus: autoFocus !== null && autoFocus !== void 0 ? autoFocus : false, onFocus: function (e) {
                            if (field.value === 0) {
                                e.target.select();
                            }
                        }, type: inputType || 'text', placeholder: props.placeholder || '', "aria-invalid": hasError, "aria-describedby": hasError || helpText ? "".concat(id !== null && id !== void 0 ? id : internalId) : undefined }, field, { value: value ? value : field.value, onChange: onChange ? onChange : field.onChange, disabled: disabled || (ctx === null || ctx === void 0 ? void 0 : ctx.isSubmitting), onWheel: function (e) {
                            if (inputType === 'number') {
                                e.preventDefault();
                                e.currentTarget.blur();
                            }
                        } })), hasError && (_jsx("div", __assign({ className: "absolute inset-y-0 right-0 flex items-center mr-2 pointer-events-none" }, { children: _jsx(FontAwesomeIcon, { className: "w-4 h-4 text-red-700", icon: faExclamationCircle, fixedWidth: true }) }))), !hasError && trailingIcon && (_jsx("div", __assign({ className: "absolute inset-y-0 right-0 flex items-center mr-2 text-red-600 pointer-events-none" }, { children: _jsx(FontAwesomeIcon, { className: "w-4 h-4 text-gray-300 dark:text-slate-600", icon: trailingIcon, fixedWidth: true }) })))] })), hasError && (_jsx("p", __assign({ className: "absolute left-0 mt-2 text-sm text-red-700 -bottom-5", id: "".concat(id) }, { children: error }))), !hasError && helpText && (_jsx("p", __assign({ className: "absolute bottom-0 left-0 mt-2 text-sm text-gray-500", id: "".concat(id) }, { children: helpText })))] })));
};
