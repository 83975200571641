var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext } from 'react';
import { NotificationContext, NotificationVariant, } from './notificationManager';
var defaultTransitionValues = {
    default: { type: 'spring', damping: 16, stiffness: 750 },
    opacity: { duration: 0.2 },
};
var cardVariants = {
    enter: {
        scale: 0.9,
        opacity: 0,
        transition: defaultTransitionValues,
    },
    visible: {
        opacity: 1,
        bottom: '0px',
        scale: 1,
        transition: defaultTransitionValues,
    },
    exit: {
        opacity: 0,
        scale: 0.9,
        transition: defaultTransitionValues,
    },
};
export var Notifications = observer(function () {
    var notifications = useContext(NotificationContext).notifications;
    if (!notifications) {
        return null;
    }
    return (_jsx("div", __assign({ className: "fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-end w-full max-w-full px-4 pb-5 pr-8 pointer-events-none" }, { children: _jsx(AnimatePresence, { children: notifications.map(function (notification) { return (_jsx(motion.div, __assign({ layout: true, initial: "enter", animate: "visible", exit: "exit", variants: cardVariants }, { children: _jsx(NotificationCard, { notification: notification }) }), notification.id)); }) }) })));
});
export var NotificationCard = observer(function (_a) {
    var notification = _a.notification;
    var closeNotification = useContext(NotificationContext).closeNotification;
    var onClose = useCallback(function () { return closeNotification(notification.id); }, [notification]);
    var _b = __read(notificationColor(notification.variant), 4), bgStyles = _b[0], titleStyles = _b[1], buttonStyles = _b[2], borderStyle = _b[3];
    return (_jsx("div", __assign({ className: "w-full max-w-lg mb-4 overflow-hidden rounded shadow-lg pointer-events-auto" }, { children: _jsxs("div", __assign({ className: classNames("p-4 flex justify-between space-x-10 relative items-center border", bgStyles, borderStyle) }, { children: [_jsxs("div", __assign({ className: "space-y-1" }, { children: [_jsx("h1", __assign({ className: classNames('font-medium', titleStyles) }, { children: notification.title })), notification.message && (_jsx("p", __assign({ className: classNames('text-sm', titleStyles) }, { children: notification.message.split('\n')[0] })))] })), _jsx("button", __assign({ className: classNames('flex items-center ml-3 p-0 text-lg hover:text-white transition-all', buttonStyles), onClick: onClose }, { children: _jsx(FontAwesomeIcon, { icon: faTimesCircle, fixedWidth: true }) }))] })) })));
});
var notificationColor = function (variant) {
    switch (variant) {
        case NotificationVariant.ERROR:
            return ['bg-red-100', 'text-red-800', 'text-red-500', 'border-red-400'];
        case NotificationVariant.INFO:
            return [
                'bg-indigo-100',
                'text-indigo-800',
                'text-indigo-500',
                'border-indigo-400',
            ];
        case NotificationVariant.OK:
            return [
                'bg-green-100',
                'text-green-800',
                'text-green-500',
                'border-green-400',
            ];
        case NotificationVariant.WARN:
            return [
                'bg-orange-100',
                'text-orange-800',
                'text-orange-500',
                'border-orange-400',
            ];
        default:
            return [
                'bg-gray-200',
                'text-gray-800',
                'text-gray-500',
                'border-gray-400',
            ];
    }
};
