var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Panel } from 'src/components';
export var Documents = function () {
    return (_jsxs("div", __assign({ className: "grid grid-cols-6 gap-6" }, { children: [_jsxs("div", __assign({ className: "col-span-6 space-y-4 md:col-span-4" }, { children: [_jsx(Panel, { children: _jsxs("div", __assign({ className: "flex items-center space-x-4" }, { children: [_jsx("div", __assign({ className: "flex items-center justify-center p-4 text-lg rounded text-amber-700 bg-amber-50" }, { children: _jsx(FontAwesomeIcon, { icon: faFolder }) })), _jsx("div", { children: _jsx("h1", __assign({ className: "text-xl font-semibold sm:text-2xl" }, { children: "Dokument" })) })] })) }), _jsx(Panel, { children: _jsxs("div", __assign({ className: "py-16" }, { children: [_jsx("p", { children: "Inga dokument" }), _jsx("p", __assign({ className: "italic" }, { children: "N\u00E4r vi delar dokument med dig s\u00E5 kommer de visas h\u00E4r." }))] })) })] })), _jsx("div", __assign({ className: "col-span-6 md:col-span-2" }, { children: _jsxs(Panel, { children: [_jsx("h2", __assign({ className: "font-semibold text-gray-900" }, { children: "Notiser" })), _jsx("p", __assign({ className: "pt-5 text-sm italic text-gray-600" }, { children: "Inga notiser \u00E4nnu." }))] }) }))] })));
};
