var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@iown/shared';
import React, { useEffect } from 'react';
import { useBoolean } from 'react-hanger';
import { LoadingBar, Panel } from 'src/components';
import classNames from 'classnames';
export var SignableContracts = function (_a) {
    var _b = _a.initialExpanded, initialExpanded = _b === void 0 ? false : _b, userId = _a.userId, contracts = _a.contracts, refetch = _a.refetch;
    var expandContract = useBoolean(initialExpanded);
    if (expandContract.value) {
        return (_jsxs(Panel, { children: [_jsxs("div", __assign({ className: "flex items-center justify-between pb-2 border-b" }, { children: [_jsx("h2", __assign({ className: "pb-2 text-2xl font-semibold" }, { children: "Dina avtal" })), _jsxs("div", __assign({ className: "flex items-center space-x-4" }, { children: [_jsx("p", { className: "text-gray-800" }), _jsx("button", __assign({ className: "flex items-center justify-center w-10 h-10 text-green-900 rounded cursor-pointer hover:bg-gray-100", onClick: expandContract.setFalse }, { children: _jsx(FontAwesomeIcon, { icon: faChevronDown }) }))] }))] })), _jsx("div", __assign({ className: "pt-4 " }, { children: _jsx(ContractsContent, { loading: false, userId: userId, contracts: contracts, refetch: refetch }) }))] }));
    }
    return (_jsx(Panel, __assign({ className: classNames('space-y-6 group', contracts.length === 0 && 'opacity-80 bg-gray-100 cursor-not-allowed', contracts.length > 0 && 'cursor-pointer '), onClick: function () {
            if (contracts.length > 0) {
                expandContract.setTrue();
            }
        } }, { children: _jsxs("div", __assign({ className: "flex items-center justify-between" }, { children: [_jsx("h2", __assign({ className: "pb-2 text-2xl font-semibold " }, { children: "Dina avtal" })), _jsxs("div", __assign({ className: "flex items-center space-x-4" }, { children: [_jsx("p", __assign({ className: "text-gray-800" }, { children: contracts.length === 0
                                ? 'Du har inga avtal i nuläget'
                                : 'Läs och signera avtal' })), _jsx("button", __assign({ className: "flex items-center justify-center w-10 h-10 text-gray-900 rounded cursor-pointer group-hover:bg-gray-100" }, { children: _jsx(FontAwesomeIcon, { icon: faChevronDown }) }))] }))] })) })));
};
var ContractsContent = function (_a) {
    var contracts = _a.contracts, userId = _a.userId, refetch = _a.refetch, loading = _a.loading;
    var signed = contracts.filter(function (contract) {
        var _a;
        var userIds = ((_a = contract.signedByUsers) !== null && _a !== void 0 ? _a : []).map(function (s) { return s === null || s === void 0 ? void 0 : s.userId; });
        if (userIds.includes(userId))
            return true;
        return false;
    });
    var toHandle = contracts.filter(function (contract) {
        var _a;
        var userIds = ((_a = contract.signedByUsers) !== null && _a !== void 0 ? _a : []).map(function (s) { return s === null || s === void 0 ? void 0 : s.userId; });
        if (!userIds.includes(userId))
            return true;
        return false;
    });
    useEffect(function () {
        var interval = setInterval(function () {
            if (toHandle.length > 0) {
                refetch();
            }
        }, 1000 * 30);
        return function () {
            clearInterval(interval);
        };
    }, [toHandle.length]);
    if (contracts.length === 0 && loading) {
        return _jsx(LoadingBar, { label: "H\u00E4mtar avtal" });
    }
    if (contracts.length === 0) {
        return (_jsxs("div", { children: [_jsx("h3", __assign({ className: "font-semibold" }, { children: "Inget just nu" })), _jsx("p", { children: "N\u00E4r vi skickar avtal till dig kommer du se dem h\u00E4r." })] }));
    }
    return (_jsxs("div", __assign({ className: "space-y-6" }, { children: [toHandle.length > 0 && (_jsxs("div", { children: [_jsx("h3", __assign({ className: "font-semibold" }, { children: "Att hantera" })), _jsx("div", __assign({ className: "py-4" }, { children: toHandle.map(function (contract, index) {
                            var _a, _b, _c;
                            var contractUrl = (_b = (_a = contract.userContractURLs) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.url;
                            return (_jsxs(React.Fragment, { children: [index > 0 && _jsx("div", { className: "w-full h-[1px] bg-gray-200" }), _jsxs("div", __assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", { children: (_c = contract.filename) === null || _c === void 0 ? void 0 : _c.split('.pdf')[0] }), _jsxs("div", __assign({ className: "flex items-center space-x-6" }, { children: [_jsx("p", __assign({ className: "italic" }, { children: "L\u00E4s och signera" })), _jsx(Button, { label: "Visa avtalet", variant: "primary", onClick: function () {
                                                            if (contractUrl) {
                                                                window.open(contractUrl, '_blank', 'titlebar=no, toolbar=no, location=yes, menubar=no, status=no, height=1300, width=1640, left=0, top=25');
                                                            }
                                                        } })] }))] })), index === 0 && toHandle.length === 1 && (_jsx("div", { className: "w-full h-[1px] bg-gray-200 mt-4" }))] }, contract.id));
                        }) }))] })), signed.length > 0 && (_jsxs("div", { children: [_jsx("h3", __assign({ className: "font-semibold" }, { children: "Signerade" })), _jsx("div", __assign({ className: "py-4 space-y-4" }, { children: signed.map(function (contract, index) {
                            var _a, _b, _c;
                            var contractUrl = (_b = (_a = contract.userContractURLs) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.url;
                            return (_jsxs(React.Fragment, { children: [index > 0 && _jsx("div", { className: "w-full h-[1px] bg-gray-200" }), _jsxs("div", __assign({ className: "flex items-center justify-between" }, { children: [_jsx("div", { children: (_c = contract.filename) === null || _c === void 0 ? void 0 : _c.split('.pdf')[0] }), _jsxs("div", __assign({ className: "flex items-center space-x-6" }, { children: [contract.signedAndSealed ? (_jsx("p", __assign({ className: "italic text-green-700" }, { children: "Signerat och slutet" }))) : (_jsx("p", __assign({ className: "italic text-gray-700" }, { children: "V\u00E4ntar p\u00E5 signatur fr\u00E5n \u00F6vriga parter" }))), _jsx(Button, { label: "Visa avtalet", onClick: function () {
                                                            if (contractUrl) {
                                                                window.open(contractUrl, '_blank', 'titlebar=no, toolbar=no, location=yes, menubar=no, status=no, height=1300, width=1640, left=0, top=25');
                                                            }
                                                        } })] }))] }))] }, contract.id));
                        }) }))] }))] })));
};
