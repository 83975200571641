var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeAutoObservable } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
var NotificationManager = /** @class */ (function () {
    function NotificationManager() {
        var _this = this;
        this._notifications = [];
        this.notify = function (notification) {
            var _a;
            // dont notify if same notification has already been created.
            if (_this._notifications.find(function (n) {
                if (n.message === notification.message &&
                    n.timeout === notification.timeout &&
                    n.title === notification.title &&
                    n.variant === notification.variant) {
                    return n;
                }
                return null;
            })) {
                return;
            }
            // Create the notification
            var id = nanoid();
            _this._notifications.unshift(__assign(__assign({ id: id }, notification), { timeout: setTimeout(function () {
                    _this.closeNotification(id);
                }, (_a = notification.timeout) !== null && _a !== void 0 ? _a : 5000) }));
            _this._notifications = _this._notifications.slice(0, 3);
        };
        this.closeNotification = function (notificationId) {
            var _a;
            clearTimeout((_a = _this._notifications.find(function (n) { return n.id === notificationId; })) === null || _a === void 0 ? void 0 : _a.timeout);
            _this._notifications = _this._notifications.filter(function (n) { return n.id !== notificationId; });
        };
        makeAutoObservable(this);
    }
    Object.defineProperty(NotificationManager.prototype, "notifications", {
        get: function () {
            return this._notifications;
        },
        enumerable: false,
        configurable: true
    });
    return NotificationManager;
}());
export var notificationManager = (function () {
    return new NotificationManager();
})();
export var NotificationContext = React.createContext(notificationManager);
export var NotificationVariant;
(function (NotificationVariant) {
    NotificationVariant["DEFAULT"] = "default";
    NotificationVariant["WARN"] = "warning";
    NotificationVariant["ERROR"] = "error";
    NotificationVariant["INFO"] = "info";
    NotificationVariant["OK"] = "success";
})(NotificationVariant || (NotificationVariant = {}));
