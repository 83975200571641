import { useEffect, useRef } from 'react';
var MOUSEDOWN = 'mousedown';
var TOUCHSTART = 'touchstart';
var events = [MOUSEDOWN, TOUCHSTART];
export function useClickOutside(
// ref: React.RefObject<HTMLElement>,
handler) {
    var handlerRef = useRef(handler);
    var ref = useRef();
    var togglerRef = useRef();
    useEffect(function () {
        handlerRef.current = handler;
    });
    useEffect(function () {
        if (!handler) {
            return;
        }
        var listener = function (event) {
            if (togglerRef &&
                togglerRef.current &&
                togglerRef.current.contains(event.target)) {
                event.stopPropagation();
                return;
            }
            if (!(ref === null || ref === void 0 ? void 0 : ref.current) ||
                !(handlerRef === null || handlerRef === void 0 ? void 0 : handlerRef.current) ||
                ref.current.contains(event.target)) {
                return;
            }
            handlerRef.current(event);
        };
        events.forEach(function (event) {
            document.addEventListener(event, listener);
        });
        return function () {
            events.forEach(function (event) {
                document.removeEventListener(event, listener);
            });
        };
    }, [handler, ref, togglerRef]);
    return [ref, togglerRef];
}
// type AnyRef =
//   | Ref<HTMLDivElement | HTMLButtonElement | HTMLLinkElement | undefined>
//   | LegacyRef<HTMLDivElement>
//   | undefined
