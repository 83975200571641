var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
export var MarkdownRenderer = function (_a) {
    var source = _a.source, className = _a.className;
    return (_jsx("div", __assign({ className: classNames('prose prose-slate dark:prose-invert', className) }, { children: _jsx(ReactMarkdown, { children: source }) })));
};
