var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { Button, FormikComponents } from '@iown/shared';
import { Formik, Form, Field } from 'formik';
import { useContext } from 'react';
import { NotificationContext, NotificationVariant, } from 'src/App/NotificationCentre';
import { useEditUserMutation } from 'src/types/graphql/types';
import * as Yup from 'yup';
export var ProfileForm = function (_a) {
    var initialValues = _a.initialValues, userId = _a.userId, refetch = _a.refetch;
    var notify = useContext(NotificationContext).notify;
    var _b = __read(useEditUserMutation(), 1), editUser = _b[0];
    return (_jsx(Formik, __assign({ initialValues: initialValues, enableReinitialize: true, validationSchema: UserProfileSchema, onSubmit: function (values, _a) {
            var setFieldError = _a.setFieldError, setSubmitting = _a.setSubmitting;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            console.log('submitting', values);
                            return [4 /*yield*/, editUser({
                                    variables: {
                                        input: {
                                            id: userId,
                                            firstName: values.firstName,
                                            lastName: values.lastName,
                                            phoneNumber: values.phoneNumber,
                                            address: values.address,
                                            postalCode: values.postalCode,
                                            city: values.city,
                                        },
                                    },
                                })];
                        case 1:
                            _b.sent();
                            return [4 /*yield*/, refetch()];
                        case 2:
                            _b.sent();
                            setSubmitting(false);
                            notify({
                                variant: NotificationVariant.INFO,
                                title: 'Profil sparad',
                            });
                            return [2 /*return*/];
                    }
                });
            });
        } }, { children: function (_a) {
            var isSubmitting = _a.isSubmitting, dirty = _a.dirty, isValid = _a.isValid;
            return (_jsxs(Form, __assign({ className: "grid grid-cols-2 gap-4" }, { children: [_jsx("h3", __assign({ className: "col-span-2 pt-6 -mb-2 text-sm font-semibold text-gray-600" }, { children: "Kontaktuppgifter" })), _jsx("div", __assign({ className: "col-span-2 md:col-span-1" }, { children: _jsx(Field, __assign({ name: "firstName" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "F\u00F6rnamn", trailingIcon: faUser, placeholder: "" }, props))); } })) })), _jsx("div", __assign({ className: "col-span-2 md:col-span-1" }, { children: _jsx(Field, __assign({ name: "lastName" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "Efternamn", trailingIcon: faUser, placeholder: "" }, props))); } })) })), _jsx("div", __assign({ className: "col-span-2 md:col-span-1" }, { children: _jsx(Field, __assign({ name: "email" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "Email", disabled: true, trailingIcon: faEnvelope, placeholder: "namn@exempel.se" }, props))); } })) })), _jsx("div", __assign({ className: "col-span-2 md:col-span-1" }, { children: _jsx(Field, __assign({ name: "phoneNumber" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "Telefonnummer", trailingIcon: faPhone, placeholder: "073 123 45 67" }, props))); } })) })), _jsx("h3", __assign({ className: "col-span-2 pt-6 -mb-2 text-sm font-semibold text-gray-600" }, { children: "Adressuppgifter" })), _jsx("div", __assign({ className: "col-span-2 md:col-span-1" }, { children: _jsx(Field, __assign({ name: "address" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "Adress", 
                                // trailingIcon={faPhone}
                                placeholder: "Exempelv\u00E4gen 10" }, props))); } })) })), _jsx("div", __assign({ className: "col-span-2 md:col-span-1" }, { children: _jsx(Field, __assign({ name: "postalCode" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "Postnummer", 
                                // trailingIcon={faPhone}
                                placeholder: "123 45" }, props))); } })) })), _jsx("div", __assign({ className: "col-span-2 md:col-span-1" }, { children: _jsx(Field, __assign({ name: "city" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "Postort", 
                                // trailingIcon={faPhone}
                                placeholder: "Malm\u00F6" }, props))); } })) })), _jsx("div", __assign({ className: "flex items-center justify-end col-span-2 pt-4" }, { children: _jsx(Button, { loading: isSubmitting, disabled: isSubmitting || !dirty || !isValid, className: "w-full md:w-auto", variant: !dirty || !isValid ? 'default' : 'primary', label: "Spara \u00E4ndringar", type: "submit" }) }))] })));
        } })));
};
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation editUser($input: EditUserInput!) {\n    editUser(input: $input) {\n      id\n    }\n  }\n"], ["\n  mutation editUser($input: EditUserInput!) {\n    editUser(input: $input) {\n      id\n    }\n  }\n"])));
var UserProfileSchema = Yup.object().shape({
    email: Yup.string()
        .email('Not a valid email address')
        .required('Email Required'),
    phoneNumber: Yup.string().required('Ditt telefonnummer är obligatoriskt.'),
    firstName: Yup.string().required('Ditt namn är obligatoriskt.'),
    lastName: Yup.string().required('Ditt namn är obligatoriskt.'),
    address: Yup.string().required('Din adress är obligatorisk.'),
    postalCode: Yup.string().required('Postnummer är obligatoriskt.'),
    city: Yup.string().required('Postort är obligatoriskt.'),
});
var templateObject_1;
