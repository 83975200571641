var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal } from '@iown/shared';
import { useBoolean } from 'react-hanger';
export var ExpandableMap = function (_a) {
    var _b, _c, _d, _e;
    var map = _a.map;
    var fullscreen = useBoolean(false);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "w-full rounded cursor-pointer group", onClick: fullscreen.setTrue }, { children: [_jsx("div", __assign({ className: "min-h-[258px] bg-gray-100" }, { children: _jsx("img", { src: (_b = map.imageUrl) !== null && _b !== void 0 ? _b : '', alt: (_c = map.name) !== null && _c !== void 0 ? _c : 'mapname' + (map === null || map === void 0 ? void 0 : map.mapType) }) })), _jsx("p", __assign({ className: "py-2 capitalize group-hover:underline underline-offset-2" }, { children: map.name }))] })), _jsx(Modal.Wrapper, __assign({ closeOnClickOutside: true, onClose: fullscreen.setFalse, maxWidthClass: "w-screen", isOpen: fullscreen.value, className: "p-0 md:p-6" }, { children: _jsx("div", __assign({ className: "flex items-center justify-center w-auto h-[calc(100vh-50px)]" }, { children: _jsx("img", { className: "object-contain w-auto h-full", src: (_d = map.imageUrl) !== null && _d !== void 0 ? _d : '', alt: (_e = map.name) !== null && _e !== void 0 ? _e : 'mapname' + (map === null || map === void 0 ? void 0 : map.mapType) }) })) }))] }));
};
