var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { fromPromise } from '@apollo/client';
import './styles/index.css';
import { store } from './store';
import { notificationManager, NotificationVariant, } from './App/NotificationCentre';
import { refreshUserAccessToken } from './utils/refreshUserAcessToken';
var httpLink = createUploadLink({
    uri: process.env.REACT_APP_SERVER_URI,
});
var authLink = setContext(function (_, _a) {
    var headers = _a.headers;
    // get the authentication token from local storage if it exists
    var token = localStorage.getItem('auth_token');
    // return the headers to the context so httpLink can read them
    return {
        headers: __assign(__assign({}, headers), { authorization: token ? "Bearer ".concat(token) : '' }),
    };
});
var isRefreshingDate = new Date();
var pendingRequests = [];
var resolvePendingRequests = function () {
    pendingRequests.map(function (callback) { return callback(); });
    pendingRequests = [];
};
var onErrorLink = onError(function (_a) {
    var e_1, _b;
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation, forward = _a.forward;
    if (graphQLErrors) {
        if (process.env.NODE_ENV === 'development') {
            console.log({ graphQLErrors: graphQLErrors });
        }
        try {
            for (var graphQLErrors_1 = __values(graphQLErrors), graphQLErrors_1_1 = graphQLErrors_1.next(); !graphQLErrors_1_1.done; graphQLErrors_1_1 = graphQLErrors_1.next()) {
                var err = graphQLErrors_1_1.value;
                switch (err === null || err === void 0 ? void 0 : err.message) {
                    case 'invalid_token':
                        var forward$ = void 0;
                        var now = new Date();
                        if (isRefreshingDate < now) {
                            var t = new Date();
                            t.setSeconds(t.getSeconds() + 10);
                            isRefreshingDate = t;
                            forward$ = fromPromise(refreshUserAccessToken(client)
                                .then(function (_a) {
                                var accessToken = _a.accessToken, refreshToken = _a.refreshToken;
                                store.setToken(accessToken);
                                store.setRefreshToken(refreshToken);
                                resolvePendingRequests();
                                return { accessToken: accessToken, refreshToken: refreshToken };
                                // return
                            })
                                /* eslint-disable no-loop-func */
                                .catch(function (error) {
                                pendingRequests = [];
                                store.clearTokens();
                                return;
                            })
                                .finally(function () {
                                //isRefreshingDate = false
                            })).filter(function (value) { return Boolean(value); });
                        }
                        else {
                            forward$ = fromPromise(new Promise(function (resolve) {
                                pendingRequests.push(function () { return resolve(); });
                            }));
                        }
                        return forward$.flatMap(function () { return forward(operation); });
                    default:
                        console.error(err.message);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (graphQLErrors_1_1 && !graphQLErrors_1_1.done && (_b = graphQLErrors_1.return)) _b.call(graphQLErrors_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    else if (networkError) {
        notificationManager.notify({
            title: 'Something went wrong',
            variant: NotificationVariant.ERROR,
            message: networkError.message,
            timeout: 3000,
        });
    }
});
var httpLinkAuth = ApolloLink.from([onErrorLink, authLink, httpLink]);
var client = new ApolloClient({
    link: authLink.concat(httpLinkAuth),
    cache: new InMemoryCache(),
});
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(ApolloProvider, __assign({ client: client }, { children: _jsx(App, {}) })) }), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
