var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, FormikComponents } from '@iown/shared';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
export var RejectForm = function (_a) {
    var showPolicy = _a.showPolicy, onSubmit = _a.onSubmit;
    return (_jsx("div", { children: _jsx(Formik, __assign({ initialValues: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                policyAccepted: false,
            }, validationSchema: AcceptSchema, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, onSubmit(values)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); } }, { children: function (_a) {
                var isSubmitting = _a.isSubmitting, dirty = _a.dirty, isValid = _a.isValid;
                return (_jsxs(Form, __assign({ className: "space-y-4" }, { children: [_jsx(Field, __assign({ name: "email" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "E-post adress", trailingIcon: faEnvelope, placeholder: "namn@exempel.se" }, props))); } })), _jsx(Field, __assign({ name: "phone" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "Telefonnummer", trailingIcon: faMobileAlt, placeholder: "073 123 45 67" }, props))); } })), _jsx(Field, __assign({ name: "firstName" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "F\u00F6rnamn", placeholder: "" }, props))); } })), _jsx(Field, __assign({ name: "lastName" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ label: "Efternamn", placeholder: "" }, props))); } })), _jsx(Field, __assign({ name: "policyAccepted" }, { children: function (props) { return (_jsx(FormikComponents.Checkbox, __assign({ label: _jsxs("p", { children: ["Jag accepterar", ' ', _jsx("span", __assign({ className: "text-indigo-700 cursor-pointer hover:underline", onClick: function (e) {
                                                e.preventDefault();
                                                showPolicy();
                                            } }, { children: "integritetspolicyn" }))] }) }, props))); } })), _jsx("div", __assign({ className: "py-4" }, { children: _jsx(Button, { loading: isSubmitting, disabled: !isValid || !dirty, variant: "danger", label: "Jag \u00E4r inte intresserad", className: "w-full", type: "submit" }) }))] })));
            } })) }));
};
var AcceptSchema = Yup.object().shape({
    firstName: Yup.string().required('Ditt namn är obligatoriskt.'),
    lastName: Yup.string().required('Ditt efternamn är obligatoriskt.'),
    email: Yup.string().email('Ogiltig e-post adress.'),
    phone: Yup.string(),
    policyAccepted: Yup.boolean()
        .isTrue()
        .required('Du behöver godkänna vår integritetspolicy.'),
});
