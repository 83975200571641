var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import classNames from 'classnames';
export var LoadingBar = function (_a) {
    var label = _a.label, className = _a.className;
    return (_jsxs("div", __assign({ className: classNames('flex flex-col', className) }, { children: [_jsx(motion.div, { initial: { opacity: 0, width: '0%' }, animate: { opacity: 1, width: "100%" }, transition: { width: { duration: 6, repeat: Infinity } }, className: "h-2 bg-gray-200 dark:rounded dark:bg-slate-800" }), _jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { delay: 0, duration: 6, repeat: Infinity }, className: "mt-1 text-xs text-gray-500 capitalize dark:text-sky-600" }, { children: label }))] })));
};
