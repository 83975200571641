var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { Button } from './Button';
var Portal = function (_a) {
    var children = _a.children;
    var el = document.createElement('div');
    useEffect(function () {
        document.body.appendChild(el);
        return function () {
            console.log('returning and removing portal el');
            document.body.removeChild(el);
        };
    }, []);
    return createPortal(children, document.body);
};
var Wrapper = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.closeLabel, closeLabel = _c === void 0 ? 'Close' : _c, _d = _a.hasCloser, hasCloser = _d === void 0 ? true : _d, _e = _a.isOpen, isOpen = _e === void 0 ? true : _e, _f = _a.maxWidthClass, maxWidthClass = _f === void 0 ? 'max-w-xl' : _f, onClose = _a.onClose, children = _a.children, _g = _a.closeOnEsc, closeOnEsc = _g === void 0 ? true : _g, _h = _a.noPortal, noPortal = _h === void 0 ? false : _h, _j = _a.closeOnClickOutside, closeOnClickOutside = _j === void 0 ? true : _j, noBackground = _a.noBackground;
    var handleKeyPress = useCallback(function (e) {
        if (isOpen && closeOnEsc && e.keyCode === 27 && onClose) {
            onClose();
        }
    }, [closeOnEsc]);
    useEffect(function () {
        window.addEventListener('keydown', handleKeyPress);
        return function () {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);
    var GetModal = function () { return (_jsxs(motion.div, __assign({ animate: { opacity: 1 }, exit: { opacity: 0 }, initial: { opacity: 0 }, transition: { duration: 0.15 }, className: classNames('scroll-container fixed z-[998] antialiased top-0 bottom-0 left-0 right-0 px-2 md:px-6 overflow-y-auto transition-all  flex content-center justify-center', noBackground
            ? ''
            : 'bg-gray-400 dark:bg-slate-800 dark:bg-opacity-90 bg-opacity-75', isOpen ? 'visible' : 'invisible'), onClick: function (e) {
            e.stopPropagation();
            if (closeOnClickOutside && onClose) {
                onClose();
            }
        } }, { children: [hasCloser && (_jsx("div", __assign({ className: "fixed top-0 right-0 z-50 h-0 p-4" }, { children: _jsx(Button, { onClick: onClose, variant: "default", icon: faTimes, className: "shadow-lg" }) }))), _jsx("div", __assign({ className: classNames('w-full m-auto', maxWidthClass) }, { children: _jsx("div", __assign({ className: classNames('flex-shrink-0 py-12', className) }, { children: children })) }))] }))); };
    return noPortal ? GetModal() : _jsx(Portal, { children: GetModal() });
};
var Container = function (_a) {
    var children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b;
    return (_jsx(motion.div, __assign({ onClick: function (e) { return e.stopPropagation(); }, className: classNames('bg-white dark:bg-slate-900 rounded-lg shadow-xl', className), animate: { y: 0, scale: 1 }, exit: { y: -15 }, initial: { y: 15, scale: 0.9 } }, { children: children })));
};
var Title = function (_a) {
    var children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b, subtitle = _a.subtitle;
    return (_jsxs("div", __assign({ className: classNames('block p-12', className) }, { children: [_jsx("h1", __assign({ className: "text-sm font-semibold tracking-wide text-gray-700 uppercase dark:text-slate-300" }, { children: children })), subtitle && (_jsx("h2", __assign({ className: "text-gray-500 dark:text-slate-400" }, { children: subtitle })))] })));
};
var Body = function (_a) {
    var children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.hasPadding, hasPadding = _c === void 0 ? true : _c;
    return (_jsx("div", __assign({ className: classNames('dark:text-slate-300', hasPadding && 'px-12', className) }, { children: children })));
};
var Footer = function (_a) {
    var children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b;
    return (_jsx("div", __assign({ className: "flex flex-row p-12 space-x-2 ".concat(className) }, { children: children })));
};
export var Modal = {
    Wrapper: Wrapper,
    Container: Container,
    Title: Title,
    Body: Body,
    Footer: Footer,
};
