var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, FormikComponents } from '@iown/shared';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { useCreateValidateLinkMutation, useValidateUserMutation, } from 'src/types/graphql/types';
import { useContext } from 'react';
import { StoreContext } from 'src/store';
import { Link } from 'react-router-dom';
export var Validate = function () {
    var token = useParams().token;
    var store = useContext(StoreContext);
    var _a = __read(useValidateUserMutation(), 2), validateUser = _a[0], _b = _a[1], loadingValidate = _b.loading, errorValidate = _b.error;
    var _c = __read(useCreateValidateLinkMutation(), 2), createValidateLink = _c[0], _d = _c[1], loadingNewLink = _d.loading, errorNewLink = _d.error;
    var navigate = useNavigate();
    if (!token || errorValidate) {
        return (_jsx("div", __assign({ className: "min-h-screen bg-red-50 flex items-center justify-center" }, { children: _jsx("div", __assign({ className: "max-w-xl w-full space-y-6 px-6" }, { children: _jsxs("div", __assign({ className: "space-y-4" }, { children: [_jsx("h1", __assign({ className: "text-4xl font-bold md:text-6xl" }, { children: "Ogiltig l\u00E4nk." })), _jsxs("div", __assign({ className: "space-y-4" }, { children: [_jsxs("p", { children: ["L\u00E4nken du anv\u00E4nt har blivit ogiltig eller \u00E4r redan anv\u00E4nd. Har du redan skapat ett l\u00F6senord trycker du", ' ', _jsx(Link, __assign({ className: "text-indigo-700", to: "/" }, { children: "h\u00E4r f\u00F6r att logga in." }))] }), _jsx("p", __assign({ className: "text-gray-400" }, { children: "Beh\u00F6ver du en ny l\u00E4nk anger du din email adress s\u00E5 f\u00E5r du en ny l\u00E4nk skickad till din email. Tryck p\u00E5 l\u00E4nken i mailet s\u00E5 snart som m\u00F6jligt." })), _jsx("div", __assign({ className: "max-w-sm" }, { children: _jsx(Formik, __assign({ initialValues: { email: '' }, validationSchema: newLinkSchema, onSubmit: function (values, _a) {
                                            var setFieldError = _a.setFieldError, setSubmitting = _a.setSubmitting;
                                            return __awaiter(void 0, void 0, void 0, function () {
                                                return __generator(this, function (_b) {
                                                    switch (_b.label) {
                                                        case 0:
                                                            console.log(values);
                                                            return [4 /*yield*/, createValidateLink({
                                                                    variables: { email: values.email },
                                                                })];
                                                        case 1:
                                                            _b.sent();
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            });
                                        } }, { children: function (_a) {
                                            var isSubmitting = _a.isSubmitting, dirty = _a.dirty, isValid = _a.isValid;
                                            return (_jsxs(Form, __assign({ className: "space-y-6" }, { children: [_jsx(Field, __assign({ name: "email" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ inputType: "text", label: "Email", trailingIcon: faEnvelope, placeholder: "namn@dom\u00E4n.se" }, props))); } })), _jsx(Button, { loading: loadingNewLink || isSubmitting, disabled: isSubmitting || !dirty || !isValid, className: "w-full py-2", variant: "primary", label: "Beg\u00E4r ny l\u00E4nk", type: "submit" })] })));
                                        } })) }))] }))] })) })) })));
    }
    return (_jsx("div", __assign({ className: "min-h-screen bg-indigo-50 flex items-center justify-center" }, { children: _jsxs("div", __assign({ className: "max-w-xl w-full space-y-6 px-6" }, { children: [_jsxs("div", __assign({ className: "space-y-4" }, { children: [_jsx("h1", __assign({ className: "text-4xl font-bold md:text-6xl" }, { children: "Skapa l\u00F6senord." })), _jsxs("div", __assign({ className: "space-y-2" }, { children: [_jsx("p", { children: "Du beh\u00F6ver ange ett l\u00F6senord f\u00F6r ditt konto." }), _jsx("p", __assign({ className: "text-gray-400 text-sm italic" }, { children: "Ett starkt l\u00F6senord b\u00F6r ha en l\u00E4ngd p\u00E5 minst 6 karakt\u00E4rer." }))] }))] })), _jsx("div", __assign({ className: "max-w-sm" }, { children: _jsx(Formik, __assign({ initialValues: { password: '' }, validationSchema: ValidateSchema, onSubmit: function (values, _a) {
                            var setFieldError = _a.setFieldError, setSubmitting = _a.setSubmitting;
                            return __awaiter(void 0, void 0, void 0, function () {
                                var data, error_1;
                                var _b, _c;
                                return __generator(this, function (_d) {
                                    switch (_d.label) {
                                        case 0:
                                            _d.trys.push([0, 2, , 3]);
                                            return [4 /*yield*/, validateUser({
                                                    variables: {
                                                        token: token !== null && token !== void 0 ? token : '',
                                                        password: values.password,
                                                    },
                                                })];
                                        case 1:
                                            data = (_d.sent()).data;
                                            store.setToken((_b = data === null || data === void 0 ? void 0 : data.validateUser) === null || _b === void 0 ? void 0 : _b.token);
                                            store.setRefreshToken((_c = data === null || data === void 0 ? void 0 : data.validateUser) === null || _c === void 0 ? void 0 : _c.refreshToken);
                                            navigate('/');
                                            return [3 /*break*/, 3];
                                        case 2:
                                            error_1 = _d.sent();
                                            console.error(error_1);
                                            return [3 /*break*/, 3];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            });
                        } }, { children: function (_a) {
                            var isSubmitting = _a.isSubmitting, dirty = _a.dirty, isValid = _a.isValid;
                            return (_jsxs(Form, __assign({ className: "space-y-6" }, { children: [_jsx(Field, __assign({ name: "password" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ inputType: "password", label: "L\u00F6senord", trailingIcon: faLock, placeholder: "password" }, props))); } })), _jsx(Field, __assign({ name: "confirmPassword" }, { children: function (props) { return (_jsx(FormikComponents.Input, __assign({ inputType: "password", label: "Upprepa l\u00F6senordet", trailingIcon: faLock, placeholder: "password" }, props))); } })), _jsx(Button, { loading: loadingValidate || isSubmitting, disabled: isSubmitting || !dirty || !isValid, className: "w-full py-2", variant: "primary", label: "Skapa l\u00F6senord", type: "submit" })] })));
                        } })) }))] })) })));
};
var ValidateSchema = Yup.object().shape({
    password: Yup.string().min(6).required('Ange ett lösenord.'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Lösenordet matchar inte.')
        .required('Bekräfta lösenordet genom att upprepa det.'),
});
var newLinkSchema = Yup.object().shape({
    email: Yup.string().email().required('Du behöver ange din email adress'),
});
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation validateUser($token: String!, $password: String!) {\n    validateUser(token: $token, password: $password) {\n      token\n      refreshToken\n    }\n  }\n"], ["\n  mutation validateUser($token: String!, $password: String!) {\n    validateUser(token: $token, password: $password) {\n      token\n      refreshToken\n    }\n  }\n"])));
gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation createValidateLink($email: String!) {\n    createValidateLink(email: $email)\n  }\n"], ["\n  mutation createValidateLink($email: String!) {\n    createValidateLink(email: $email)\n  }\n"])));
var templateObject_1, templateObject_2;
