var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createContext, forwardRef, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { faChevronDown, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBoolean } from 'react-hanger';
import { useClickOutside } from '@iown/shared/hooks';
import { useButtonGroupIndex } from '@iown/shared/components';
var buttonDropdownContext = createContext(null);
export function useButtonDropdown() {
    return useContext(buttonDropdownContext);
}
export var Button = forwardRef(function (_a, ref) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, icon = _a.icon, label = _a.label, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.loading, loading = _d === void 0 ? false : _d, onClick = _a.onClick, _e = _a.size, size = _e === void 0 ? 'md' : _e, dropdown = _a.dropdown, _f = _a.dropdownIcon, dropdownIcon = _f === void 0 ? faChevronDown : _f, _g = _a.variant, variant = _g === void 0 ? 'default' : _g, _h = _a.type, type = _h === void 0 ? 'button' : _h, overrideGroup = _a.overrideGroup, reverseIcon = _a.reverseIcon, props = __rest(_a, ["className", "icon", "label", "disabled", "loading", "onClick", "size", "dropdown", "dropdownIcon", "variant", "type", "overrideGroup", "reverseIcon"]);
    var hasDropdown = !!dropdown;
    if (hasDropdown && onClick) {
        console.warn('passing onClick to button with dropdown. The onClick will be overridden');
    }
    var isDropdownOpen = useBoolean(false);
    var dropdownContext = useMemo(function () { return ({ isOpen: isDropdownOpen }); }, [isDropdownOpen]);
    var _j = __read(useClickOutside(isDropdownOpen.setFalse), 2), dropdownMenuRef = _j[0], dropdownToggleRef = _j[1];
    var groupIndex = useButtonGroupIndex();
    var isGroup = !overrideGroup && groupIndex !== null;
    var isGroupStart = isGroup && groupIndex === 0;
    var isGroupEnd = isGroup && groupIndex === 1;
    return (_jsxs("div", __assign({ className: classNames('relative inline-block', variant !== 'transparent' &&
            !(loading || disabled || isGroup) &&
            'shadow-sm', isGroup && !isGroupStart && '-mx-px', !isGroup && 'rounded', isGroupStart && 'rounded-l', isGroupEnd && 'rounded-r', className), ref: ref }, { children: [_jsxs(ButtonOrAnchor, __assign({}, props, { type: type, ref: dropdownToggleRef, disabled: disabled || loading, onClick: hasDropdown
                    ? function (e) {
                        e.stopPropagation();
                        isDropdownOpen.toggle();
                    }
                    : onClick, className: classNames((!label && icon) || (!label && hasDropdown)
                    ? squareSizeClasses[size]
                    : sizeClasses[size], 'flex items-center justify-center dark:hover:bg-opacity-75 border leading-5 font-semibold focus:outline-none transition ease-out duration-75 w-full whitespace-no-wrap h-full', !(loading || disabled) && variantClassnames[variant].hover, (loading || disabled) && 'cursor-not-allowed', disabled || loading
                    ? variantClassnames[variant].disabled
                    : variantClassnames[variant].base, !isGroup && 'rounded', isGroupStart && 'rounded-l', isGroupEnd && 'rounded-r') }, { children: [loading && (_jsx("div", __assign({ className: "absolute flex items-center justify-center w-full h-full" }, { children: _jsx("div", __assign({ className: classNames(variant === 'important' && 'text-teal-50', variant === 'primary' && 'text-indigo-100', variant === 'default' && 'text-gray-500', variant === 'transparent' && 'text-indigo-500') }, { children: _jsx(FontAwesomeIcon, { className: "w-5 h-5 text-base animate-spin", icon: faCircleNotch }) })) }))), _jsxs("div", __assign({ className: "flex items-center space-x-2 whitespace-no-wrap" }, { children: [icon && !reverseIcon && (_jsx("span", __assign({ className: classNames('flex items-center justify-center', !!label && 'w-4 h-4', !label && !isGroup && 'w-5 h-5', !label && isGroup && 'w-4 h-4') }, { children: _jsx(FontAwesomeIcon, { icon: icon }) }))), label && (_jsx("span", __assign({ className: classNames(loading && 'invisible') }, { children: label }))), hasDropdown && dropdownIcon && (_jsx("span", __assign({ style: { marginTop: '1px', marginBottom: '-1px' } }, { children: _jsx(FontAwesomeIcon, { icon: dropdownIcon }) }))), icon && reverseIcon && (_jsx("span", __assign({ className: classNames('flex items-center justify-center w-4 h-4', { 'opacity-75': !!label }) }, { children: _jsx(FontAwesomeIcon, { icon: icon }) })))] }))] })), hasDropdown && isDropdownOpen.value && (_jsx(buttonDropdownContext.Provider, __assign({ value: dropdownContext }, { children: _jsx("div", __assign({ className: "absolute right-0 z-40 w-48", ref: dropdownMenuRef }, { children: dropdown })) })))] })));
});
var ButtonOrAnchor = forwardRef(function (_a, ref) {
    var type = _a.type, props = __rest(_a, ["type"]);
    if (props.href) {
        return _jsx("a", __assign({}, props, { ref: ref }));
    }
    return _jsx("button", __assign({ type: type }, props, { ref: ref }));
});
var sizeClasses = {
    xs: 'text-xs p-0.5',
    sm: 'text-sm px-2.5 py-1',
    md: 'text-base px-4 py-2',
    lg: 'text-lg px-6 py-3',
    custom: '',
};
var squareSizeClasses = {
    xs: 'text-xs p-0.5',
    sm: 'text-sm  p-1.5',
    md: 'text-base p-2',
    lg: 'text-lg p-3',
    custom: '',
};
var variantClassnames = {
    important: {
        base: 'text-white bg-boniPurplePrimary border-purple-400 relative',
        hover: 'hover:bg-boniPurpleSecondary hover:border-purple-500',
        disabled: 'text-purple-100 bg-purple-300 border-purple-400',
    },
    primary: {
        base: 'text-white dark:bg-sky-700 dark:border-sky-700 bg-indigo-500 border-indigo-600 relative',
        hover: ' hover:bg-indigo-400 dark:hover:bg-sky-800 hover:border-indigo-500 hover:text-white active:border-indigo-500 dark:active:border-sky-800 focus:ring-2 active:ring-4 dark:active-ring-4 ring-indigo-300 dark:ring-sky-600',
        disabled: 'text-indigo-100 dark:text-sky-300 dark:bg-sky-900 dark:border-sky-900 bg-indigo-400 border-indigo-500',
    },
    danger: {
        base: 'text-white bg-red-500 border-red-600 relative',
        hover: 'hover:bg-red-400 hover:border-red-500 active:bg-red-600 active:border-red-700 focus:ring-2 active:ring-4 ring-red-300 focus:shadow-outline-red',
        disabled: 'text-red-200 bg-red-400 border-red-500',
    },
    transparent: {
        base: 'text-gray-700/90 border-transparent border-opacity-0 dark:text-slate-400/90',
        hover: 'hover:text-gray-900 active:text-gray-700 hover:bg-gray-100/70 dark:hover:bg-slate-800/70 dark:hover:text-slate-400 dark:active:text-slate-300',
        disabled: 'text-gray-400/90 dark:text-slate-600/70 border-transparent',
    },
    default: {
        base: 'text-iown-dark dark:text-slate-500 bg-white dark:bg-slate-800 dark:border-transparent border-iown-light relative',
        hover: ' hover:border-iown-medium hover:text-iown-dark active:border-iown-medium dark:hover:text-slate-400 focus:ring-2 active:ring-3 dark:active:ring-4 dark:ring-slate-700 ring-iown-medium',
        disabled: 'text-iown-medium bg-white dark:bg-slate-800 border-iown-light dark:border-transparent dark:text-slate-700',
    },
};
