var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
export var ActionType;
(function (ActionType) {
    ActionType["Email"] = "EMAIL";
    ActionType["Letter"] = "LETTER";
    ActionType["Meeting"] = "MEETING";
    ActionType["Noanswer"] = "NOANSWER";
    ActionType["Phonecall"] = "PHONECALL";
    ActionType["Projectupdate"] = "PROJECTUPDATE";
    ActionType["Referral"] = "REFERRAL";
})(ActionType || (ActionType = {}));
export var AuthScope;
(function (AuthScope) {
    AuthScope["Admin"] = "ADMIN";
    AuthScope["Client"] = "CLIENT";
    AuthScope["Partner"] = "PARTNER";
})(AuthScope || (AuthScope = {}));
export var ClientInviteResponse;
(function (ClientInviteResponse) {
    ClientInviteResponse["Accepted"] = "ACCEPTED";
    ClientInviteResponse["Rejected"] = "REJECTED";
})(ClientInviteResponse || (ClientInviteResponse = {}));
export var ContactMethod;
(function (ContactMethod) {
    ContactMethod["Email"] = "EMAIL";
    ContactMethod["Phone"] = "PHONE";
    ContactMethod["Sms"] = "SMS";
})(ContactMethod || (ContactMethod = {}));
export var ContractAuthenticationMethod;
(function (ContractAuthenticationMethod) {
    ContractAuthenticationMethod["SeBankid"] = "SE_BANKID";
    ContractAuthenticationMethod["SmsPin"] = "SMS_PIN";
    ContractAuthenticationMethod["Standard"] = "STANDARD";
})(ContractAuthenticationMethod || (ContractAuthenticationMethod = {}));
export var ContractStatus;
(function (ContractStatus) {
    ContractStatus["Canceled"] = "CANCELED";
    ContractStatus["Closed"] = "CLOSED";
    ContractStatus["DocumentError"] = "DOCUMENT_ERROR";
    ContractStatus["Pending"] = "PENDING";
    ContractStatus["Preparation"] = "PREPARATION";
    ContractStatus["Rejected"] = "REJECTED";
    ContractStatus["Timedout"] = "TIMEDOUT";
})(ContractStatus || (ContractStatus = {}));
export var ContractType;
(function (ContractType) {
    ContractType["Unknown"] = "UNKNOWN";
})(ContractType || (ContractType = {}));
export var Country;
(function (Country) {
    Country["Norway"] = "NORWAY";
    Country["Sweden"] = "SWEDEN";
})(Country || (Country = {}));
export var DocumentType;
(function (DocumentType) {
    DocumentType["Contract"] = "CONTRACT";
    DocumentType["Image"] = "IMAGE";
    DocumentType["Information"] = "INFORMATION";
    DocumentType["Other"] = "OTHER";
    DocumentType["Referral"] = "REFERRAL";
    DocumentType["Video"] = "VIDEO";
})(DocumentType || (DocumentType = {}));
export var MapLayerType;
(function (MapLayerType) {
    MapLayerType["Other"] = "OTHER";
    MapLayerType["Road"] = "ROAD";
    MapLayerType["Turbine"] = "TURBINE";
})(MapLayerType || (MapLayerType = {}));
export var MapType;
(function (MapType) {
    MapType["Elevation"] = "ELEVATION";
    MapType["Layout"] = "LAYOUT";
    MapType["Orthophoto"] = "ORTHOPHOTO";
    MapType["Overview"] = "OVERVIEW";
    MapType["Property"] = "PROPERTY";
    MapType["Terrain"] = "TERRAIN";
})(MapType || (MapType = {}));
export var MarketPackageStatus;
(function (MarketPackageStatus) {
    MarketPackageStatus["Archived"] = "ARCHIVED";
    MarketPackageStatus["Draft"] = "DRAFT";
    MarketPackageStatus["Live"] = "LIVE";
})(MarketPackageStatus || (MarketPackageStatus = {}));
export var MilestoneTitle;
(function (MilestoneTitle) {
    MilestoneTitle["Ms_1"] = "MS_1";
    MilestoneTitle["Ms_2"] = "MS_2";
    MilestoneTitle["Ms_3"] = "MS_3";
    MilestoneTitle["Ms_4"] = "MS_4";
    MilestoneTitle["Ms_5"] = "MS_5";
    MilestoneTitle["Ms_6"] = "MS_6";
    MilestoneTitle["Ms_7"] = "MS_7";
    MilestoneTitle["Ms_8"] = "MS_8";
    MilestoneTitle["Ms_9"] = "MS_9";
    MilestoneTitle["Ms_10"] = "MS_10";
    MilestoneTitle["Ms_11"] = "MS_11";
    MilestoneTitle["Ms_12"] = "MS_12";
    MilestoneTitle["Ms_13"] = "MS_13";
    MilestoneTitle["Ms_14"] = "MS_14";
    MilestoneTitle["Ms_15"] = "MS_15";
    MilestoneTitle["Ms_16"] = "MS_16";
    MilestoneTitle["Ms_17"] = "MS_17";
    MilestoneTitle["Ms_18"] = "MS_18";
})(MilestoneTitle || (MilestoneTitle = {}));
export var ProjectClassification;
(function (ProjectClassification) {
    ProjectClassification["Base"] = "BASE";
    ProjectClassification["Key"] = "KEY";
})(ProjectClassification || (ProjectClassification = {}));
export var ProjectPriority;
(function (ProjectPriority) {
    ProjectPriority["High"] = "HIGH";
    ProjectPriority["Low"] = "LOW";
    ProjectPriority["Medium"] = "MEDIUM";
    ProjectPriority["Unprioritized"] = "UNPRIORITIZED";
})(ProjectPriority || (ProjectPriority = {}));
export var ProjectSortOption;
(function (ProjectSortOption) {
    ProjectSortOption["Class"] = "CLASS";
    ProjectSortOption["County"] = "COUNTY";
    ProjectSortOption["Default"] = "DEFAULT";
    ProjectSortOption["Effect"] = "EFFECT";
    ProjectSortOption["Landownercount"] = "LANDOWNERCOUNT";
    ProjectSortOption["Letter"] = "LETTER";
    ProjectSortOption["Municipality"] = "MUNICIPALITY";
    ProjectSortOption["Name"] = "NAME";
    ProjectSortOption["Phone"] = "PHONE";
    ProjectSortOption["Priority"] = "PRIORITY";
    ProjectSortOption["Properties"] = "PROPERTIES";
    ProjectSortOption["Turbines"] = "TURBINES";
    ProjectSortOption["Status"] = "status";
})(ProjectSortOption || (ProjectSortOption = {}));
export var ProjectStatus;
(function (ProjectStatus) {
    ProjectStatus["Archived"] = "ARCHIVED";
    ProjectStatus["Cancelled"] = "CANCELLED";
    ProjectStatus["Completed"] = "COMPLETED";
    ProjectStatus["Evaluation"] = "EVALUATION";
    ProjectStatus["Initial"] = "INITIAL";
    ProjectStatus["Interestcheck"] = "INTERESTCHECK";
    ProjectStatus["Lead"] = "LEAD";
    ProjectStatus["Negotiation"] = "NEGOTIATION";
})(ProjectStatus || (ProjectStatus = {}));
export var ProjectType;
(function (ProjectType) {
    ProjectType["Solar"] = "SOLAR";
    ProjectType["Wind"] = "WIND";
})(ProjectType || (ProjectType = {}));
export var QaCategory;
(function (QaCategory) {
    QaCategory["Financial"] = "FINANCIAL";
    QaCategory["Legal"] = "LEGAL";
    QaCategory["Other"] = "OTHER";
    QaCategory["Technical"] = "TECHNICAL";
})(QaCategory || (QaCategory = {}));
export var QaPriority;
(function (QaPriority) {
    QaPriority["High"] = "HIGH";
    QaPriority["Low"] = "LOW";
    QaPriority["Medium"] = "MEDIUM";
})(QaPriority || (QaPriority = {}));
export var QaStatus;
(function (QaStatus) {
    QaStatus["Closed"] = "CLOSED";
    QaStatus["Open"] = "OPEN";
})(QaStatus || (QaStatus = {}));
export var QaSortOption;
(function (QaSortOption) {
    QaSortOption["Author"] = "AUTHOR";
    QaSortOption["Category"] = "CATEGORY";
    QaSortOption["Default"] = "DEFAULT";
    QaSortOption["Priority"] = "PRIORITY";
    QaSortOption["Status"] = "STATUS";
    QaSortOption["Topic"] = "TOPIC";
})(QaSortOption || (QaSortOption = {}));
export var ReferralSortOption;
(function (ReferralSortOption) {
    ReferralSortOption["Default"] = "DEFAULT";
    ReferralSortOption["Project"] = "PROJECT";
    ReferralSortOption["Status"] = "STATUS";
})(ReferralSortOption || (ReferralSortOption = {}));
export var ReferralStatus;
(function (ReferralStatus) {
    ReferralStatus["Approved"] = "APPROVED";
    ReferralStatus["Rejected"] = "REJECTED";
    ReferralStatus["Submitted"] = "SUBMITTED";
    ReferralStatus["ToBeSubmitted"] = "TO_BE_SUBMITTED";
})(ReferralStatus || (ReferralStatus = {}));
export var ReferralType;
(function (ReferralType) {
    ReferralType["Forsvarsmakten"] = "Forsvarsmakten";
    ReferralType["Lfv"] = "LFV";
    ReferralType["Lst"] = "LST";
    ReferralType["Msb"] = "MSB";
    ReferralType["Naturvardsverket"] = "Naturvardsverket";
    ReferralType["Pts"] = "PTS";
    ReferralType["Riksantikvarieambetet"] = "Riksantikvarieambetet";
    ReferralType["SvenskaKraftnat"] = "Svenska_Kraftnat";
    ReferralType["TekniskaForvaltningenKommun"] = "Tekniska_Forvaltningen_Kommun";
    ReferralType["Telecom_3Gis"] = "Telecom_3GIS";
    ReferralType["TelecomHi3GAccess"] = "Telecom_Hi3G_Access";
    ReferralType["TelecomTele2"] = "Telecom_Tele2";
    ReferralType["TelecomTelenor"] = "Telecom_Telenor";
    ReferralType["TelecomTelia"] = "Telecom_Telia";
    ReferralType["TelecomTeracomSvenskaUmts"] = "Telecom_Teracom_Svenska_UMTS";
    ReferralType["Teracom"] = "Teracom";
    ReferralType["Trafikverket"] = "Trafikverket";
})(ReferralType || (ReferralType = {}));
export var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "ADMIN";
    UserRole["Base"] = "BASE";
    UserRole["Key"] = "KEY";
    UserRole["Landowner"] = "LANDOWNER";
    UserRole["Partner"] = "PARTNER";
})(UserRole || (UserRole = {}));
export var UsersQueryFilter;
(function (UsersQueryFilter) {
    UsersQueryFilter["Notlinkedtoproject"] = "NOTLINKEDTOPROJECT";
})(UsersQueryFilter || (UsersQueryFilter = {}));
export var UsersSortOption;
(function (UsersSortOption) {
    UsersSortOption["Default"] = "DEFAULT";
    UsersSortOption["Name"] = "NAME";
    UsersSortOption["Project"] = "PROJECT";
    UsersSortOption["Property"] = "PROPERTY";
})(UsersSortOption || (UsersSortOption = {}));
export var CurrentUserDocument = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query currentUser {\n    currentUser {\n      id\n      userName\n      firstName\n      lastName\n      email\n      role\n      profilePicture\n    }\n  }\n"], ["\n  query currentUser {\n    currentUser {\n      id\n      userName\n      firstName\n      lastName\n      email\n      role\n      profilePicture\n    }\n  }\n"
    /**
     * __useCurrentUserQuery__
     *
     * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
     * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
     * you can use to render your UI.
     *
     * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
     *
     * @example
     * const { data, loading, error } = useCurrentUserQuery({
     *   variables: {
     *   },
     * });
     */
])));
/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserDocument, options);
}
export var LogoutDocument = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation logout($refreshToken: String!) {\n    logout(refreshToken: $refreshToken)\n  }\n"], ["\n  mutation logout($refreshToken: String!) {\n    logout(refreshToken: $refreshToken)\n  }\n"])));
/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(LogoutDocument, options);
}
export var RefreshAccessTokenDocument = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation RefreshAccessToken($accessToken: String!, $refreshToken: String!) {\n    refreshAccessToken(accessToken: $accessToken, refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n"], ["\n  mutation RefreshAccessToken($accessToken: String!, $refreshToken: String!) {\n    refreshAccessToken(accessToken: $accessToken, refreshToken: $refreshToken) {\n      accessToken\n      refreshToken\n    }\n  }\n"])));
/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RefreshAccessTokenDocument, options);
}
export var CurrentUserContractsDocument = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  query currentUserContracts {\n    currentUser {\n      id\n      firstName\n      lastName\n      contracts {\n        id\n        createdAt\n        dueDate\n        status\n        filename\n        signedAndSealed\n        userContractURLs {\n          id\n          userId\n          email\n          url\n        }\n        signedByUsers {\n          id\n          userId\n          email\n          signedDate\n        }\n      }\n    }\n  }\n"], ["\n  query currentUserContracts {\n    currentUser {\n      id\n      firstName\n      lastName\n      contracts {\n        id\n        createdAt\n        dueDate\n        status\n        filename\n        signedAndSealed\n        userContractURLs {\n          id\n          userId\n          email\n          url\n        }\n        signedByUsers {\n          id\n          userId\n          email\n          signedDate\n        }\n      }\n    }\n  }\n"
    /**
     * __useCurrentUserContractsQuery__
     *
     * To run a query within a React component, call `useCurrentUserContractsQuery` and pass it any options that fit your needs.
     * When your component renders, `useCurrentUserContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
     * you can use to render your UI.
     *
     * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
     *
     * @example
     * const { data, loading, error } = useCurrentUserContractsQuery({
     *   variables: {
     *   },
     * });
     */
])));
/**
 * __useCurrentUserContractsQuery__
 *
 * To run a query within a React component, call `useCurrentUserContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserContractsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserContractsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserContractsDocument, options);
}
export function useCurrentUserContractsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserContractsDocument, options);
}
export var ForgotPasswordDocument = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  mutation forgotPassword($email: String!) {\n    forgotPassword(email: $email)\n  }\n"], ["\n  mutation forgotPassword($email: String!) {\n    forgotPassword(email: $email)\n  }\n"])));
/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ForgotPasswordDocument, options);
}
export var PendingClientInviteDocument = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  query pendingClientInvite($code: String) {\n    pendingClientInvite(query: { code: $code }) {\n      id\n      response\n      userName\n      email\n    }\n  }\n"], ["\n  query pendingClientInvite($code: String) {\n    pendingClientInvite(query: { code: $code }) {\n      id\n      response\n      userName\n      email\n    }\n  }\n"
    /**
     * __usePendingClientInviteQuery__
     *
     * To run a query within a React component, call `usePendingClientInviteQuery` and pass it any options that fit your needs.
     * When your component renders, `usePendingClientInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
     * you can use to render your UI.
     *
     * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
     *
     * @example
     * const { data, loading, error } = usePendingClientInviteQuery({
     *   variables: {
     *      code: // value for 'code'
     *   },
     * });
     */
])));
/**
 * __usePendingClientInviteQuery__
 *
 * To run a query within a React component, call `usePendingClientInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingClientInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingClientInviteQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePendingClientInviteQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PendingClientInviteDocument, options);
}
export function usePendingClientInviteLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PendingClientInviteDocument, options);
}
export var RespondToClientInviteDocument = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  mutation respondToClientInvite($input: ClientInviteInput!) {\n    respondToClientInvite(input: $input)\n  }\n"], ["\n  mutation respondToClientInvite($input: ClientInviteInput!) {\n    respondToClientInvite(input: $input)\n  }\n"])));
/**
 * __useRespondToClientInviteMutation__
 *
 * To run a mutation, you first call `useRespondToClientInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToClientInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToClientInviteMutation, { data, loading, error }] = useRespondToClientInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRespondToClientInviteMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RespondToClientInviteDocument, options);
}
export var CurrentUserMapsDocument = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  query currentUserMaps {\n    currentUser {\n      id\n      firstName\n      lastName\n      maps {\n        id\n        updatedAt\n        name\n        mapType\n        imageUrl\n        project {\n          id\n          name\n        }\n      }\n    }\n  }\n"], ["\n  query currentUserMaps {\n    currentUser {\n      id\n      firstName\n      lastName\n      maps {\n        id\n        updatedAt\n        name\n        mapType\n        imageUrl\n        project {\n          id\n          name\n        }\n      }\n    }\n  }\n"
    /**
     * __useCurrentUserMapsQuery__
     *
     * To run a query within a React component, call `useCurrentUserMapsQuery` and pass it any options that fit your needs.
     * When your component renders, `useCurrentUserMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
     * you can use to render your UI.
     *
     * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
     *
     * @example
     * const { data, loading, error } = useCurrentUserMapsQuery({
     *   variables: {
     *   },
     * });
     */
])));
/**
 * __useCurrentUserMapsQuery__
 *
 * To run a query within a React component, call `useCurrentUserMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserMapsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserMapsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserMapsDocument, options);
}
export function useCurrentUserMapsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserMapsDocument, options);
}
export var UploadProfilePictureDocument = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  mutation uploadProfilePicture($userId: ID!, $image: Upload!) {\n    uploadProfilePicture(userId: $userId, image: $image) {\n      id\n      profilePicture\n    }\n  }\n"], ["\n  mutation uploadProfilePicture($userId: ID!, $image: Upload!) {\n    uploadProfilePicture(userId: $userId, image: $image) {\n      id\n      profilePicture\n    }\n  }\n"])));
/**
 * __useUploadProfilePictureMutation__
 *
 * To run a mutation, you first call `useUploadProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePictureMutation, { data, loading, error }] = useUploadProfilePictureMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadProfilePictureMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UploadProfilePictureDocument, options);
}
export var UserProfileDocument = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  query userProfile {\n    currentUser {\n      id\n      userName\n      firstName\n      lastName\n      email\n      phoneNumber\n      address\n      postalCode\n      city\n      profilePicture\n    }\n  }\n"], ["\n  query userProfile {\n    currentUser {\n      id\n      userName\n      firstName\n      lastName\n      email\n      phoneNumber\n      address\n      postalCode\n      city\n      profilePicture\n    }\n  }\n"
    /**
     * __useUserProfileQuery__
     *
     * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
     * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
     * you can use to render your UI.
     *
     * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
     *
     * @example
     * const { data, loading, error } = useUserProfileQuery({
     *   variables: {
     *   },
     * });
     */
])));
/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserProfileDocument, options);
}
export function useUserProfileLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserProfileDocument, options);
}
export var EditUserDocument = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  mutation editUser($input: EditUserInput!) {\n    editUser(input: $input) {\n      id\n    }\n  }\n"], ["\n  mutation editUser($input: EditUserInput!) {\n    editUser(input: $input) {\n      id\n    }\n  }\n"])));
/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EditUserDocument, options);
}
export var CurrentUserProjectInfoDocument = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  query currentUserProjectInfo {\n    currentUser {\n      id\n      firstName\n      lastName\n      projects {\n        id\n        name\n        county\n        municipality\n        projectType\n        numberOfProperties\n        effect {\n          MIN\n          MAX\n        }\n        potentialNumberOfWindmills {\n          MIN\n          MAX\n        }\n        estimatedPropertyCount {\n          MIN\n          MAX\n        }\n        maps {\n          id\n          name\n          mapType\n          imageUrl\n        }\n      }\n      realEstates {\n        id\n        updatedAt\n        code\n        sections\n        turbines\n        projects {\n          id\n        }\n      }\n    }\n  }\n"], ["\n  query currentUserProjectInfo {\n    currentUser {\n      id\n      firstName\n      lastName\n      projects {\n        id\n        name\n        county\n        municipality\n        projectType\n        numberOfProperties\n        effect {\n          MIN\n          MAX\n        }\n        potentialNumberOfWindmills {\n          MIN\n          MAX\n        }\n        estimatedPropertyCount {\n          MIN\n          MAX\n        }\n        maps {\n          id\n          name\n          mapType\n          imageUrl\n        }\n      }\n      realEstates {\n        id\n        updatedAt\n        code\n        sections\n        turbines\n        projects {\n          id\n        }\n      }\n    }\n  }\n"
    /**
     * __useCurrentUserProjectInfoQuery__
     *
     * To run a query within a React component, call `useCurrentUserProjectInfoQuery` and pass it any options that fit your needs.
     * When your component renders, `useCurrentUserProjectInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
     * you can use to render your UI.
     *
     * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
     *
     * @example
     * const { data, loading, error } = useCurrentUserProjectInfoQuery({
     *   variables: {
     *   },
     * });
     */
])));
/**
 * __useCurrentUserProjectInfoQuery__
 *
 * To run a query within a React component, call `useCurrentUserProjectInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserProjectInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserProjectInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserProjectInfoQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserProjectInfoDocument, options);
}
export function useCurrentUserProjectInfoLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserProjectInfoDocument, options);
}
export var ResetPasswordDocument = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  mutation resetPassword($token: String!, $password: String!) {\n    resetPassword(token: $token, password: $password)\n  }\n"], ["\n  mutation resetPassword($token: String!, $password: String!) {\n    resetPassword(token: $token, password: $password)\n  }\n"])));
/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ResetPasswordDocument, options);
}
export var LoginDocument = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  mutation login($email: String!, $password: String!, $scope: AuthScope!) {\n    login(email: $email, password: $password, scope: $scope) {\n      user {\n        userName\n        email\n        role\n      }\n      token\n      refreshToken\n    }\n  }\n"], ["\n  mutation login($email: String!, $password: String!, $scope: AuthScope!) {\n    login(email: $email, password: $password, scope: $scope) {\n      user {\n        userName\n        email\n        role\n      }\n      token\n      refreshToken\n    }\n  }\n"])));
/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useLoginMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(LoginDocument, options);
}
export var CurrentUserTermsDataDocument = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  query currentUserTermsData {\n    currentUser {\n      id\n      firstName\n      lastName\n      termChanges {\n        id\n        createdAt\n        updatedAt\n        body\n        landowner {\n          id\n        }\n        author {\n          id\n          firstName\n          lastName\n        }\n      }\n      acceptedTerms {\n        id\n        createdAt\n        termSheet {\n          id\n        }\n        landowner {\n          id\n        }\n      }\n      termSheet {\n        id\n        body\n      }\n    }\n  }\n"], ["\n  query currentUserTermsData {\n    currentUser {\n      id\n      firstName\n      lastName\n      termChanges {\n        id\n        createdAt\n        updatedAt\n        body\n        landowner {\n          id\n        }\n        author {\n          id\n          firstName\n          lastName\n        }\n      }\n      acceptedTerms {\n        id\n        createdAt\n        termSheet {\n          id\n        }\n        landowner {\n          id\n        }\n      }\n      termSheet {\n        id\n        body\n      }\n    }\n  }\n"
    /**
     * __useCurrentUserTermsDataQuery__
     *
     * To run a query within a React component, call `useCurrentUserTermsDataQuery` and pass it any options that fit your needs.
     * When your component renders, `useCurrentUserTermsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
     * you can use to render your UI.
     *
     * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
     *
     * @example
     * const { data, loading, error } = useCurrentUserTermsDataQuery({
     *   variables: {
     *   },
     * });
     */
])));
/**
 * __useCurrentUserTermsDataQuery__
 *
 * To run a query within a React component, call `useCurrentUserTermsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserTermsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserTermsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserTermsDataQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserTermsDataDocument, options);
}
export function useCurrentUserTermsDataLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserTermsDataDocument, options);
}
export var AcceptTermsDocument = gql(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  mutation acceptTerms($input: AcceptTermsInput!) {\n    acceptTerms(input: $input) {\n      id\n      createdAt\n      termSheet {\n        id\n      }\n      landowner {\n        id\n      }\n    }\n  }\n"], ["\n  mutation acceptTerms($input: AcceptTermsInput!) {\n    acceptTerms(input: $input) {\n      id\n      createdAt\n      termSheet {\n        id\n      }\n      landowner {\n        id\n      }\n    }\n  }\n"])));
/**
 * __useAcceptTermsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsMutation, { data, loading, error }] = useAcceptTermsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptTermsMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AcceptTermsDocument, options);
}
export var ValidateUserDocument = gql(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  mutation validateUser($token: String!, $password: String!) {\n    validateUser(token: $token, password: $password) {\n      token\n      refreshToken\n    }\n  }\n"], ["\n  mutation validateUser($token: String!, $password: String!) {\n    validateUser(token: $token, password: $password) {\n      token\n      refreshToken\n    }\n  }\n"])));
/**
 * __useValidateUserMutation__
 *
 * To run a mutation, you first call `useValidateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateUserMutation, { data, loading, error }] = useValidateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useValidateUserMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ValidateUserDocument, options);
}
export var CreateValidateLinkDocument = gql(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  mutation createValidateLink($email: String!) {\n    createValidateLink(email: $email)\n  }\n"], ["\n  mutation createValidateLink($email: String!) {\n    createValidateLink(email: $email)\n  }\n"])));
/**
 * __useCreateValidateLinkMutation__
 *
 * To run a mutation, you first call `useCreateValidateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateValidateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createValidateLinkMutation, { data, loading, error }] = useCreateValidateLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateValidateLinkMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateValidateLinkDocument, options);
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
