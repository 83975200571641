import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route, Navigate } from 'react-router';
import { ForgotPassword } from 'src/views/ForgotPassword';
import { Home } from 'src/views/Home';
import { Contracts, Documents, Invitation, Maps, Messages, Profile, Project, ResetPassword, SignIn, Validate, } from '../views';
import { NavigationLayout } from './components/NavigationLayout';
export function AuthenticatedRoutes() {
    return (_jsx(NavigationLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsx(Route, { path: "/project", element: _jsx(Project, {}) }), _jsx(Route, { path: "/maps", element: _jsx(Maps, {}) }), _jsx(Route, { path: "/contracts", element: _jsx(Contracts, {}) }), _jsx(Route, { path: "/messages", element: _jsx(Messages, {}) }), _jsx(Route, { path: "/documents", element: _jsx(Documents, {}) }), _jsx(Route, { path: "/profile", element: _jsx(Profile, {}) }), _jsx(Route, { path: "/inbjudan/*", element: _jsx(Navigate, { to: "/" }) }), _jsx(Route, { path: "/validate/*", element: _jsx(Navigate, { to: "/" }) })] }) }));
}
export function UnauthenticatedRoutes() {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(SignIn, {}) }), _jsx(Route, { path: "/inbjudan/:code", element: _jsx(Invitation, {}) }), _jsx(Route, { path: "/validate/:token", element: _jsx(Validate, {}) }), _jsx(Route, { path: "/reset-password/:token", element: _jsx(ResetPassword, {}) }), _jsx(Route, { path: "/forgot-password", element: _jsx(ForgotPassword, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/" }) })] }));
}
