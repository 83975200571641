var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql } from '@apollo/client';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { ExpandableMap, Panel, TurbineLoader } from 'src/components';
import { useCurrentUserMapsQuery, useCurrentUserProjectInfoQuery, } from 'src/types/graphql/types';
export var Maps = function () {
    var _a, _b;
    var _c = useCurrentUserMapsQuery(), data = _c.data, loading = _c.loading;
    var _d = useCurrentUserProjectInfoQuery(), dataProjects = _d.data, loadingProjects = _d.loading;
    var _e = __read(useState(dataProjects === null || dataProjects === void 0 ? void 0 : dataProjects.currentUser.projects[0]), 2), selectedProject = _e[0], setSelectedProject = _e[1];
    var maps = useMemo(function () {
        var _a;
        return ((_a = data === null || data === void 0 ? void 0 : data.currentUser.maps) !== null && _a !== void 0 ? _a : []).filter(function (m) { var _a; return ((_a = m === null || m === void 0 ? void 0 : m.project) === null || _a === void 0 ? void 0 : _a.id) === (selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.id); });
    }, [selectedProject, data, dataProjects]);
    useEffect(function () {
        if (!selectedProject) {
            setSelectedProject(dataProjects === null || dataProjects === void 0 ? void 0 : dataProjects.currentUser.projects[0]);
        }
    }, [data, dataProjects]);
    var noticeMessages = maps.map(function (m) {
        var mapDate = formatDistanceToNow(new Date(m === null || m === void 0 ? void 0 : m.updatedAt));
        if (!['year', 'month'].includes(mapDate)) {
            return {
                title: 'Ny karta',
                message: "Kartan \"".concat(m === null || m === void 0 ? void 0 : m.name, "\" har nyligen lagts till."),
                mapName: m === null || m === void 0 ? void 0 : m.name,
            };
        }
        return null;
    });
    // TODO: Separate Sections per project
    return (_jsxs("div", __assign({ className: "grid grid-cols-6 gap-6" }, { children: [_jsxs("div", __assign({ className: "col-span-6 space-y-4 md:col-span-4" }, { children: [_jsxs(Panel, { children: [_jsxs("div", __assign({ className: "flex items-center space-x-4" }, { children: [_jsx("div", __assign({ className: "flex items-center justify-center p-4 text-lg text-teal-700 rounded bg-teal-50" }, { children: _jsx(FontAwesomeIcon, { icon: faLayerGroup }) })), _jsx("div", { children: _jsx("h1", __assign({ className: "text-xl font-semibold sm:text-2xl" }, { children: "Kartor" })) })] })), ((_a = dataProjects === null || dataProjects === void 0 ? void 0 : dataProjects.currentUser.projects) !== null && _a !== void 0 ? _a : []).length > 1 && (_jsx("div", __assign({ className: "pt-4 space-x-4" }, { children: (_b = dataProjects === null || dataProjects === void 0 ? void 0 : dataProjects.currentUser.projects) === null || _b === void 0 ? void 0 : _b.map(function (p) { return (_jsx("button", __assign({ onClick: function () { return setSelectedProject(p); }, className: classNames('decoration-2 font-semibold text-gray-800 underline-offset-4', (selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.id) === (p === null || p === void 0 ? void 0 : p.id)
                                        ? 'underline decoration-cyan-500'
                                        : 'hover:underline decoration-teal-500') }, { children: p === null || p === void 0 ? void 0 : p.name }), "button-".concat(p === null || p === void 0 ? void 0 : p.id))); }) })))] }), (!data || !dataProjects) && (loading || loadingProjects) && (_jsx("div", __assign({ className: "py-8" }, { children: _jsx(TurbineLoader, { withBase: true, label: "H\u00E4mtar kartor" }) }))), _jsx(Panel, { children: maps.length === 0 && !loading && !loadingProjects ? (_jsxs("div", __assign({ className: "py-16" }, { children: [_jsx("p", { children: "Inga Kartor" }), _jsx("p", __assign({ className: "italic" }, { children: "N\u00E4r vi delar kartor med dig s\u00E5 kommer de visas h\u00E4r." }))] }))) : (_jsx("div", __assign({ className: "grid grid-cols-1 row-auto gap-6 md:grid-cols-2" }, { children: maps.map(function (m) {
                                if (m) {
                                    return _jsx(ExpandableMap, { map: m }, m === null || m === void 0 ? void 0 : m.id);
                                }
                                return null;
                            }) }))) })] })), _jsx("div", __assign({ className: "col-span-6 md:col-span-2" }, { children: _jsxs(Panel, { children: [' ', _jsx("h2", __assign({ className: "font-semibold text-gray-900" }, { children: "Notiser" })), _jsxs("ul", __assign({ className: "flex-col divide-y" }, { children: [noticeMessages.length === 0 && (_jsx("li", __assign({ className: "py-5" }, { children: _jsx("p", __assign({ className: "mt-1 overflow-hidden text-sm italic text-gray-600 text-ellipsis max-h-14" }, { children: "Inga notiser just nu." })) }))), noticeMessages.map(function (n, index) {
                                    var _a;
                                    if (n) {
                                        var splitted = n.message.split('"');
                                        return (_jsxs("li", __assign({ className: "py-5" }, { children: [_jsx("h2", __assign({ className: "text-sm font-semibold text-gray-800" }, { children: n.title })), _jsxs("p", __assign({ className: "mt-1 overflow-hidden text-sm text-gray-600 text-ellipsis max-h-14" }, { children: [splitted[0], _jsx("span", __assign({ className: "italic font-semibold capitalize" }, { children: splitted[1] })), ' ', splitted[2]] }))] }), (_a = n.mapName) !== null && _a !== void 0 ? _a : '' + index));
                                    }
                                    return null;
                                })] }))] }) }))] })));
};
gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query currentUserMaps {\n    currentUser {\n      id\n      firstName\n      lastName\n      maps {\n        id\n        updatedAt\n        name\n        mapType\n        imageUrl\n        project {\n          id\n          name\n        }\n      }\n    }\n  }\n"], ["\n  query currentUserMaps {\n    currentUser {\n      id\n      firstName\n      lastName\n      maps {\n        id\n        updatedAt\n        name\n        mapType\n        imageUrl\n        project {\n          id\n          name\n        }\n      }\n    }\n  }\n"])));
var templateObject_1;
